import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {MinimalGroup} from "./groups";
import {WeatherForecastType} from "./idleOverlays";
import {Station} from "./station";
import {MinimalUnit} from "./unit";

export class StationBoardSession {
    public constructor(
        public id: string,
        public name: string,
        public creationTs: Date,
        public lastConnectedTs: Date | null,
    ) {}

    static parse(o: any): ParseResult<StationBoardSession> {
        try {
            return ParseUtils.parseSuccess(new StationBoardSession(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getUNIXTimestampDate(o, "creationTs"),
                ParseUtils.getUNIXTimestampDateOrNull(o, "lastConnectedTs"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<StationBoardSession>(`Invalid StationBoardSession Model: ${e.message}`);
        }
    }
}

export class MinimalStationBoard {
    public constructor(
        public id: string,
        public name: string,
    ) {}

    static parse(o: any): ParseResult<MinimalStationBoard> {
        try {
            return ParseUtils.parseSuccess(new MinimalStationBoard(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MinimalStationBoard>(`Invalid MinimalStationBoard Model: ${e.message}`);
        }
    }
}

export enum StationBoardActiveLayer { route, hydrants, users, apparatus, supplementals, responders, turnout, streetView, criticalBanner, drone }

export enum StationBoardInactiveLayer { users, apparatus, stations, openJobs, currentWeather, forecast5 }

export enum StationBoardBaseLayer { streets, satellite, hybrid, dark, highContrast }

export enum StationBoardActiveZoom { jobLocation, fullRoute, halfRoute, none }

export type StationBoardInactiveZoom = {type: "boundary"} | {type: "custom", distance: number};

export enum StationBoardTurnoutStartCondition { receivedTime, jobTime, jobCreation }

export enum StationBoardScreenSaverMode { off, standBy, none }

function parseStationBoardInactiveZoom(o: any): ParseResult<StationBoardInactiveZoom> {
    try {
        const type = ParseUtils.getString(o, "type");
        if (type == "boundary") {
            return ParseUtils.parseSuccess<StationBoardInactiveZoom>({type: "boundary"});
        } else if (type == "custom") {
            return ParseUtils.parseSuccess<StationBoardInactiveZoom>({
                type: "custom",
                distance: ParseUtils.getNumber(o, "distance"),
            });
        } else {
            return ParseUtils.parseFailure<StationBoardInactiveZoom>(`Invalid StationBoardInactiveZoom Model: Invalid type '${type}'`);
        }
    } catch (e) {
        return ParseUtils.parseFailure<StationBoardInactiveZoom>(`Invalid StationBoardInactiveZoom Model: ${e.message}`);
    }
}

export type UnitColorMap = { [key: string]: string };

function parseUnitColorMap(o: any): ParseResult<UnitColorMap> {
    try {
        return ParseUtils.parseSuccess<UnitColorMap>(o as UnitColorMap); // lol
    } catch (e) {
        return ParseUtils.parseFailure<UnitColorMap>(`Invalid UnitColorMap: ${e.message}`);
    }
}

export class StationBoardConfig extends MinimalStationBoard {
    public dayStart: Date;
    public dayEnd: Date;

    public constructor(
        public id: string,
        public name: string,
        public units: MinimalUnit[],
        public messagingGroup: MinimalGroup | null,
        public station: Station,
        public activeLayers: StationBoardActiveLayer[],
        public inactiveLayers: StationBoardInactiveLayer[],
        public baseLayer: StationBoardBaseLayer,
        public activeTime: number,
        public activeZoom: StationBoardActiveZoom,
        public inactiveZoom: StationBoardInactiveZoom,
        public messageDuration: number,
        public userInactiveTime: number,
        public userExpireTime: number,
        public apparatusInactiveTime: number,
        public apparatusExpireTime: number,
        public dayStartMin: number,
        public dayEndMin: number,
        public dayTurnoutTime: number,
        public nightTurnoutTime: number,
        public redTime: number | null,
        public blinkTime: number | null,
        public turnoutStart: StationBoardTurnoutStartCondition,
        public screenSaverTimeout: number | null,
        public screenSaverModeDay: StationBoardScreenSaverMode | null,
        public screenSaverModeNight: StationBoardScreenSaverMode | null,
        public unitColors: UnitColorMap | null,
    ) {
        super(id, name);

        this.dayStart = StationBoardConfig.constructDateFromMinutes(dayStartMin);
        this.dayEnd = StationBoardConfig.constructDateFromMinutes(dayEndMin);
    }

    private static constructDateFromMinutes(mins: number): Date {
        const date = new Date();
        const dayStartHours = Math.floor(mins / 60);
        const dayStartMinutes = mins - (dayStartHours * 60);
        date.setHours(dayStartHours);
        date.setMinutes(dayStartMinutes);
        return date;
    }

    willDisplayWeather(): boolean {
        return this.inactiveLayers.indexOf(StationBoardInactiveLayer.currentWeather) > -1 || this.inactiveLayers.indexOf(StationBoardInactiveLayer.forecast5) > -1;
    }

    weatherForecastType(): WeatherForecastType {
        // this isn't great. we are guaranteed by the API to only receive one forecast type, and the management site
        // enforces a client-side check to make sure the user can't select two. but... it's still theoretically possible
        // for the db to contain two in the inactiveLayers field, so if that happens we're only taking the first
        // that the API gives us.
        const filteredLayers = this.inactiveLayers.filter(layer => layer == StationBoardInactiveLayer.forecast5);
        if (filteredLayers.length <= 0) {
            return null;
        }
        return filteredLayers[0] as WeatherForecastType;
    }

    static parse(o: any): ParseResult<StationBoardConfig> {
        try {
            return ParseUtils.parseSuccess(new StationBoardConfig(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getArrayOfSubobjects(o, "units", MinimalUnit.parse, "throw"),
                ParseUtils.getSubobjectOrNull(o, "messagingGroup", MinimalGroup.parse),
                ParseUtils.getSubobject(o, "station", Station.parse),
                ParseUtils.getArray(o, "activeLayers").map((layerString: string) => (StationBoardActiveLayer as any)[layerString]),
                ParseUtils.getArray(o, "inactiveLayers").map((layerString: string) => (StationBoardInactiveLayer as any)[layerString]),
                ParseUtils.getEnum(o, "baseLayer", StationBoardBaseLayer),
                ParseUtils.getNumber(o, "activeTime"),
                ParseUtils.getEnum(o, "activeZoom", StationBoardActiveZoom),
                ParseUtils.getSubobject(o, "inactiveZoom", parseStationBoardInactiveZoom),
                ParseUtils.getNumber(o, "messageDuration"),
                ParseUtils.getNumber(o, "userInactiveTime"),
                ParseUtils.getNumber(o, "userExpireTime"),
                ParseUtils.getNumber(o, "apparatusInactiveTime"),
                ParseUtils.getNumber(o, "apparatusExpireTime"),
                ParseUtils.getNumber(o, "dayStart"),
                ParseUtils.getNumber(o, "dayEnd"),
                ParseUtils.getNumber(o, "dayTurnoutTime"),
                ParseUtils.getNumber(o, "nightTurnoutTime"),
                ParseUtils.getNumberOrNull(o, "redTime"),
                ParseUtils.getNumberOrNull(o, "blinkTime"),
                ParseUtils.getEnum(o, "turnoutStart", StationBoardTurnoutStartCondition),
                ParseUtils.getNumberOrNull(o, "screenSaverTime"),
                ParseUtils.getEnumOrNull(o, "ssModeDay", StationBoardScreenSaverMode),
                ParseUtils.getEnumOrNull(o, "ssModeNight", StationBoardScreenSaverMode),
                ParseUtils.getSubobjectOrNull(o, "unitColors", parseUnitColorMap),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<StationBoardConfig>(`Invalid StationBoardConfig Model: ${e.message}`);
        }
    }
}
