import * as React from "react";
import {Card} from "semantic-ui-react";
import {Message, MessageContentType} from "../../models/message";
import {BryxApi} from "../../utils/bryxApi";
import {DateUtils} from "../../utils/dateUtils";

export type MessageOverlayViewStatus = {key: "hidden"} | {key: "shown", message: Message, active: boolean};

interface MessageOverlayProps {
    viewStatus: MessageOverlayViewStatus;
}

export class MessageOverlay extends React.Component<MessageOverlayProps, {}> {

    private renderCard(): React.ReactNode {
        const {viewStatus} = this.props;
        if (viewStatus.key == "hidden") {
            return null;
        }
        const message = viewStatus.message;

        let text = undefined;
        let image = undefined;
        if (message.content.type == MessageContentType.text || message.content.type == MessageContentType.bryx) {
            text = message.content.text;
        } else {
            image = BryxApi.getImageUrl(message.content.imageId);
        }

        return (
            <Card className={viewStatus.active ? "message-active" : undefined}
                  header={message.sender.name}
                  description={text}
                  image={image}
                  meta={DateUtils.formatDateTime(message.timeSent)}/>
        );
    }

    render() {
        return (
            <div className='message-container'>
                {this.renderCard()}
            </div>
        );
    }
}
