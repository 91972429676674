import {ParseUtils} from "@bryxinc/lunch";
import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {MinimalJob} from "./job";

// MapUpdate

export type IdleLayersUpdateNewJob = {
    type: "newJob",
    job: MinimalJob,
};
export type IdleLayersUpdateJob = {
    type: "updatedJob",
    job: MinimalJob,
};
export type IdleLayersUpdateClosedJob = {
    type: "closedJob",
    closed: string,
};
export type IdleLayersInitialData = {
    type: "initialData",
    jobs: MinimalJob[],
};
export type IdleLayersUpdate = IdleLayersUpdateNewJob | IdleLayersUpdateClosedJob | IdleLayersInitialData | IdleLayersUpdateJob;

export function parseIdleLayersUpdate(message: BryxWebSocketMessage): ApiResult<IdleLayersUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");

                switch (type) {
                    case "newJob":
                        return apiSuccess<IdleLayersUpdateNewJob>({
                            type: "newJob",
                            job: ParseUtils.getSubobject(message.data, "job", MinimalJob.parse),
                        });
                    case "closedJob":
                        return apiSuccess<IdleLayersUpdateClosedJob>({
                            type: "closedJob",
                            closed: ParseUtils.getString(message.data, "closed"),
                        });
                    case "updatedJob":
                        return apiSuccess<IdleLayersUpdateJob>({
                            type: "updatedJob",
                            job: ParseUtils.getSubobject(message.data, "job", MinimalJob.parse),
                        });
                    default:
                        return apiFailure(null, `Invalid IdleLayersUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }

                return apiSuccess<IdleLayersInitialData>({
                    type: "initialData",
                    jobs: ParseUtils.getArrayOfSubobjects(message.initialData, "jobs", MinimalJob.parse, "throw"),
                });
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid IdleLayersUpdate Model: ${e.message}`);
    }
}
