import {Point} from "geojson";
import * as React from "react";
import {Marker} from "react-leaflet";
import {RouteComponentProps} from "react-router";
import {Loader} from "semantic-ui-react";
import {config} from "../config";
import {StationBoardActiveLayer, StationBoardActiveZoom, StationBoardInactiveLayer} from "../models/board";
import {SessionData} from "../models/boardUpdate";
import {CurrentWeatherForecast, WeatherForecast, WeatherOverlayData} from "../models/idleOverlays";
import {ListJob} from "../models/job";
import {JobType} from "../models/jobTypeInformation";
import {JobLayerItem, LayerType, MapLayer, MapLayerItem} from "../models/map";
import {MapClientType} from "../models/mapClient";
import {AudioUtils} from "../utils/audioUtils";
import {BryxColors} from "../utils/bryxColors";
import {BryxLocal} from "../utils/bryxLocal";
import {DateUtils} from "../utils/dateUtils";
import {GeoUtils} from "../utils/geoUtils";
import {IdleLayerManager, IdleLayerManagerObserver} from "../utils/idleLayerManager";
import {JobManager, JobManagerUpdateObserver} from "../utils/jobManager";
import {MapManager, MapManagerObserver} from "../utils/mapManager";
import {SessionManager, SessionManagerObserver} from "../utils/sessionManager";
import {WeatherManager, WeatherManagerObserver} from "../utils/weatherManager";
import {BryxGeoJSONLayer} from "./bryxGeoJSONLayer";
import {BryxMap} from "./bryxMap";
import {InfoOverlay, InfoOverlayViewStatus} from "./overlay/infoOverlay";
import AlertOverlay from "./overlay/infoOverlays/alertOverlay";
import {JobOverlayDoorbell} from "./overlay/infoOverlays/doorbellOverlay";
import DroneInfoOverlay from "./overlay/infoOverlays/droneInfoOverlay";
import {JobListOverlay} from "./overlay/jobListOverlay";
import {JobOverlay} from "./overlay/jobOverlay";
import {MessageOverlay, MessageOverlayViewStatus} from "./overlay/messageOverlay";
import DroneMarker from "./rotatedMarker";
import LatLngBounds = L.LatLngBounds;

export interface StationBoardState {
    sessionData: SessionData | null;
    openJobs: ListJob[] | null;
    displayJob: ListJob | null;
    currentTime: Date;
    mapLayers: MapLayer[] | null;
    bounds: LatLngBounds | null;
    windowWidth: number;
    windowHeight: number;
    weatherData: WeatherOverlayData;
    agencyOpenJobs: ListJob[] | null;
}

export abstract class BaseStationBoard extends React.Component<RouteComponentProps<{}>, StationBoardState>
    implements JobManagerUpdateObserver, SessionManagerObserver, MapManagerObserver,
        WeatherManagerObserver, IdleLayerManagerObserver {
    private static readonly ACTIVE_JOB_MAX_DISTANCE = 650; // 650 m
    private static readonly HYDRANTS_MAX_BOUNDS_DIAGONAL = 1600; // 1600 m
    private dateUpdateTimerId: number | null = null;
    private transitionTimerId: number | null = null;

    constructor(props: RouteComponentProps<{}>, context: any) {
        super(props, context);

        this.state = {
            sessionData: SessionManager.shared.sessionData,
            mapLayers: MapManager.shared.layers,
            openJobs: JobManager.shared.openJobs,
            displayJob: null,
            currentTime: DateUtils.bryxNow(),
            bounds: null,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            weatherData: {
                current: null,
                forecast: null,
            },
            agencyOpenJobs: null,
        };
    }

    // SessionManagerObserver functions

    sessionManagerDidUpdateData(sessionData: SessionData): void {
        this.setState({
            sessionData: sessionData,
        }, () => {
            this.updateIdleComponents();
            this.updateDisplayJob("syncAndUpdateMap");
            this.onInitialized();
        });
    }

    // JobManagerObserver functions

    public jobManagerDidFail(msg: string): void {
        config.warn(`jobManagerDidFail: ${msg}`);
    }

    jobManagerDidUpdateJobs(openJobs: ListJob[], additionsPossible: boolean): void {
        this.setState({
            openJobs: openJobs,
        }, () => this.updateDisplayJob(additionsPossible ? "reset" : "sync"));
    }

    // MapManagerObserver functions

    mapManagerDidUpdateLayers(layers: MapLayer[]): void {
        this.setState({
            mapLayers: layers,
        });
    }

    // WeatherManagerObserver functions

    weatherManagerDidUpdateCurrentWeather(newForecast: CurrentWeatherForecast) {
        this.setState({
            weatherData: {
                current: newForecast,
                forecast: this.state.weatherData.forecast,
            },
        });
    }

    weatherManagerDidUpdateForecast(newForecast: WeatherForecast[]) {
        this.setState({
            weatherData: {
                current: this.state.weatherData.current,
                forecast: newForecast,
            },
        });
    }

    // IdleLayerManagerObserver functions

    idleLayerManagerDidUpdateOpenJobs(jobs: ListJob[]) {
        this.setState({
            agencyOpenJobs: jobs,
        });
    }

    idleLayerManagerDidAddJob(newJob: ListJob) {
        const {agencyOpenJobs} = this.state;
        if (agencyOpenJobs == null) {
            this.setState({
                agencyOpenJobs: [newJob],
            });
            return;
        }
        if (!agencyOpenJobs.some(j => j.id == newJob.id)) {
            agencyOpenJobs.unshift(newJob);
            this.setState({
                agencyOpenJobs,
            });
        }
        if (BryxLocal.getItem<boolean>("playSound")) {
            AudioUtils.jobSound.currentTime = 0;
            AudioUtils.jobSound.play();
        }
    }

    idleLayerManagerDidCloseJob(id: string) {
        const {agencyOpenJobs} = this.state;
        if (agencyOpenJobs == null) {
            return;
        }
        this.setState({
            agencyOpenJobs: agencyOpenJobs.filter(job => job.id != id),
        });
    }

    idleLayerManagerDidUpdateJob(job: ListJob) {
        const {agencyOpenJobs} = this.state;
        if (agencyOpenJobs == null) {
            return;
        }
        const index = agencyOpenJobs.map(j => j.id).indexOf(job.id);
        agencyOpenJobs[index] = job;
        this.setState({
            agencyOpenJobs: agencyOpenJobs,
        });
    }

    abstract onInitialized(): void;

    componentDidMount() {
        JobManager.shared.registerObserver(this);
        MapManager.shared.registerObserver(this);
        SessionManager.shared.registerObserver(this);
        WeatherManager.shared.registerObserver(this);
        IdleLayerManager.shared.registerObserver(this);

        this.updateIdleComponents();
        this.updateDisplayJob("sync");
        this.resetTransitionTimer();

        this.dateUpdateTimerId = window.setInterval(() => {
            this.setState({currentTime: DateUtils.bryxNow()}, () => this.updateDisplayJob("sync"));
        }, 1000);
    }

    componentWillUnmount() {
        if (this.dateUpdateTimerId != null) {
            clearInterval(this.dateUpdateTimerId);
            this.dateUpdateTimerId = null;
        }
        if (this.transitionTimerId != null) {
            clearInterval(this.transitionTimerId);
            this.transitionTimerId = null;
        }

        JobManager.shared.unregisterObserver(this);
        MapManager.shared.unregisterObserver(this);
        SessionManager.shared.unregisterObserver(this);
        WeatherManager.shared.unregisterObserver(this);
        IdleLayerManager.shared.unregisterObserver(this);
    }

    private resetTransitionTimer() {
        if (this.transitionTimerId != null) {
            window.clearInterval(this.transitionTimerId);
        }
        this.transitionTimerId = window.setInterval(() => {
            this.updateDisplayJob("cycle");
        }, 10 * 1000);
    }

    private getActiveJobs(): ListJob[] {
        const activeTime = this.state.sessionData != null ? this.state.sessionData.board.activeTime : null;
        if (activeTime == null || this.state.openJobs == null) {
            return [];
        }
        return this.state.openJobs.filter(o => o.isActive(activeTime, this.state.currentTime));
    }

    private updateDisplayJob(type: "cycle" | "sync" | "syncAndUpdateMap" | "reset") {
        const activeJobs = this.getActiveJobs();

        if (activeJobs.length == 0) {
            this.setState({displayJob: null}, () => this.updateMapParams());
        }
        if (type == "cycle") {
            const displayJobIndex = this.state.displayJob != null ? activeJobs.map(j => j.id).indexOf(this.state.displayJob.id) : -1;
            if (displayJobIndex != -1) {
                this.setState({displayJob: activeJobs[(displayJobIndex + 1) % activeJobs.length]}, () => this.updateMapParams());
            } else {
                this.setState({displayJob: activeJobs[0]}, () => this.updateMapParams());
            }
        } else if (type == "reset") {
            this.setState({displayJob: activeJobs[0]}, () => this.updateMapParams());
            this.resetTransitionTimer();
        } else {
            if (this.state.displayJob == null) {
                this.setState({displayJob: activeJobs[0]}, () => this.updateMapParams());
            } else if (type == "syncAndUpdateMap") {
                this.updateMapParams();
            }
        }
    }

    private updateIdleComponents() {
        const {sessionData} = this.state;

        if (sessionData == null) {
            return;
        }

        if (sessionData.board.willDisplayWeather()) {
            WeatherManager.shared.setParams({
                current: sessionData.board.inactiveLayers.indexOf(StationBoardInactiveLayer.currentWeather) > -1,
                forecast: sessionData.board.weatherForecastType(),
            });
        }

        if (sessionData.board.inactiveLayers.indexOf(StationBoardInactiveLayer.openJobs) > -1) {
            IdleLayerManager.shared.setParams({
                layers: ['openJobs'],
            });
        }
    }

    private static renderMapLayerItem(item: MapLayerItem, sessionData: SessionData): JSX.Element | null {
        const seenJobKeys: string[] = [];
        switch (item.itemType) {
            case "hydrant":
                return (
                    <Marker
                        key={item.location.coordinates.toString()}
                        position={GeoUtils.geoJsonToLatLng(item.location as Point)}
                        icon={BryxMap.hydrantIcon({
                            mainSize: item.mainSize,
                            color: item.color,
                            inService: true,
                            location: item.location as Point,
                        })}
                    />
                );
            case "job":
                if (seenJobKeys.indexOf(item.id) > -1) {
                    return null;
                }
                seenJobKeys.push(item.id);
                return (
                    <Marker
                        key={`job@${item.id}`}
                        icon={BryxMap.jobTypeToIcon(item.type.type)}
                        position={GeoUtils.geoJsonToLatLng(item.location as Point)}
                    />
                );
            case "station":
                return (
                    <Marker
                        key={item.id}
                        icon={BryxMap.stationIcon}
                        position={GeoUtils.geoJsonToLatLng(item.location as Point)}
                    />
                );
            case "client":
                const duration = DateUtils.duration(item.ts, DateUtils.bryxNow());
                const clientType = item.toMapClient().info.type;

                const expireTime = (clientType == MapClientType.apparatus ? sessionData.board.apparatusExpireTime : sessionData.board.userExpireTime);
                const staleTime = (clientType == MapClientType.apparatus ? sessionData.board.apparatusInactiveTime : sessionData.board.userInactiveTime);

                if (duration.asSeconds() < -expireTime) {
                    return null;
                } else {
                    return (
                        <Marker
                            key={item.id}
                            icon={BryxMap.mapClientIcon(item.toMapClient(), duration.asSeconds() < -staleTime)}
                            position={GeoUtils.geoJsonToLatLng(item.location)}
                            zIndexOffset={100}
                        />
                    );
                }
            case "agency":
                return (
                    <BryxGeoJSONLayer key={item.id} geojson={item.location}/>
                );
        }
    }

    protected static generateMapChildren(apiLayers: MapLayer[], displayJob: ListJob | null, sessionData: SessionData, bounds: LatLngBounds): JSX.Element[] {
        const children: JSX.Element[] = [];
        apiLayers.forEach(apiLayer => {
            children.push(...apiLayer.items.map(item => (item.itemType == "job" && displayJob != null && item.id == displayJob.id ? null : this.renderMapLayerItem(item, sessionData))).filter(x => x != null) as JSX.Element[]);
        });
        if (displayJob != null && displayJob.centroid != null) {
            children.push((
                <Marker
                    key={`job@${displayJob.id}`}
                    icon={BryxMap.jobTypeToIcon(displayJob.typeInformation.type)}
                    position={GeoUtils.geoJsonToLatLng(displayJob.centroid as Point)}
                />
            ));
            children.push((
                <Marker
                    key="source-station"
                    icon={BryxMap.stationIcon}
                    position={GeoUtils.geoJsonToLatLng(sessionData.board.station.location)}
                />
            ));
            const showRoute = sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.route);
            if (showRoute && displayJob.route != null) {
                children.push((
                    <BryxGeoJSONLayer key={`job:${displayJob.id}:route`} color="#3388ff"
                                      geojson={displayJob.route.lineString}/>
                ));
            }
            const showHydrants = sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.hydrants);
            if (showHydrants && displayJob.typeInformation.type == JobType.fire && displayJob.hydrants != null && bounds.getNorthWest().distanceTo(bounds.getSouthEast()) <= BaseStationBoard.HYDRANTS_MAX_BOUNDS_DIAGONAL) {
                children.push(...displayJob.hydrants.map(h => (
                    <Marker
                        key={h.location.coordinates.toString()}
                        position={GeoUtils.geoJsonToLatLng(h.location as Point)}
                        icon={BryxMap.hydrantIcon({
                            mainSize: h.mainSize,
                            color: h.color,
                            inService: true,
                            location: h.location as Point,
                        })}
                    />
                )));
            }

            if (sessionData.drone != null) {
                if (sessionData.drone.location != null) {
                    // if altitude, if heading, annotated marker??
                    // const rotateStyle = `rotate(${sessionData.drone.heading} deg) scale (1, 1)`;
                    children.push((
                        <DroneMarker
                            location={GeoUtils.geoJsonToLatLng(sessionData.drone.location)}
                            altitude={sessionData.drone.altitude}
                            speed={sessionData.drone.speed}
                            heading={sessionData.drone.heading}
                        />
                    ));
                }
                if (sessionData.drone.path != null) {
                    children.push((
                        <BryxGeoJSONLayer key="drone-flight-path" color="green" geojson={sessionData.drone.path}/>
                    ));
                }
            }
        }
        return children;
    }

    private updateMapParams() {
        const {displayJob, sessionData} = this.state;
        if (sessionData == null) {
            // Session data not available, wait for loading to finish.
            return;
        }
        const stationLatLng = GeoUtils.geoJsonToLatLng(sessionData.board.station.location);

        let bounds: LatLngBounds;
        const layers: LayerType[] = [];
        if (displayJob != null && displayJob.centroid != null && sessionData.board.activeZoom != StationBoardActiveZoom.none) {
            const jobLatLng = GeoUtils.geoJsonToLatLng(displayJob.centroid);
            if (sessionData.board.activeZoom == StationBoardActiveZoom.jobLocation) {
                bounds = jobLatLng.toBounds(BaseStationBoard.ACTIVE_JOB_MAX_DISTANCE);
            } else {
                const boundingPoint: [number, number] =
                    sessionData.board.activeZoom == StationBoardActiveZoom.fullRoute
                        ? [stationLatLng.lat, stationLatLng.lng]
                        : [(jobLatLng.lat + stationLatLng.lat) / 2.0, (jobLatLng.lng + stationLatLng.lng) / 2.0];
                bounds = GeoUtils.boundsFromLatLngs([boundingPoint, [jobLatLng.lat, jobLatLng.lng]]) || jobLatLng.toBounds(BaseStationBoard.ACTIVE_JOB_MAX_DISTANCE);
            }
            sessionData.board.activeLayers.forEach(a => {
                switch (a) {
                    case StationBoardActiveLayer.users:
                        layers.push(LayerType.users);
                        break;
                    case StationBoardActiveLayer.apparatus:
                        layers.push(LayerType.apparatus);
                        break;
                }
            });
        } else {
            if (sessionData.board.inactiveZoom.type == "boundary") {
                bounds = sessionData.agencyBounds;
            } else {
                bounds = stationLatLng.toBounds(sessionData.board.inactiveZoom.distance * 0.98);
            }
            layers.push(LayerType.jobs);
            sessionData.board.inactiveLayers.forEach(a => {
                switch (a) {
                    case StationBoardInactiveLayer.apparatus:
                        layers.push(LayerType.apparatus);
                        break;
                    case StationBoardInactiveLayer.users:
                        layers.push(LayerType.users);
                        break;
                    case StationBoardInactiveLayer.stations:
                        layers.push(LayerType.stations);
                        break;
                }
            });
        }

        this.setState({bounds: bounds});

        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        const topRight = [northEast.lng, northEast.lat];
        const bottomLeft = [southWest.lng, southWest.lat];

        MapManager.shared.setParams(topRight, bottomLeft, layers);
    }

    render() {
        const {
            sessionData,
            currentTime,
            openJobs,
            bounds,
            windowWidth,
            windowHeight,
            displayJob,
            agencyOpenJobs,
        } = this.state;
        let {mapLayers} = this.state;

        if (sessionData == null || mapLayers == null || bounds == null || openJobs == null) {
            return (
                <div id="mapPage" className="pageContent" style={{backgroundColor: BryxColors.brandRed}}>
                    <Loader inverted active/>
                </div>
            );
        }

        let messageViewStatus: MessageOverlayViewStatus = {key: "hidden"};

        if (sessionData.message != null) {
            const messageAge = (currentTime.getTime() - sessionData.message.timeSent.getTime());
            const expireTime = sessionData.board.messageDuration * 1000;
            const activeTime = (0.1 * expireTime);
            if (messageAge <= expireTime) {
                messageViewStatus = {key: "shown", message: sessionData.message, active: messageAge <= activeTime};
            }
        }

        let infoViewStatus: InfoOverlayViewStatus = {key: "hidden"};

        if (this.state.weatherData.current != null || this.state.weatherData.forecast != null) {
            infoViewStatus = {
                key: "shown",
                weather: this.state.weatherData,
                units: null,
            };
        }

        // TODO: implement unit status fetching here
        /*if (infoViewStatus.key == "shown") {
            infoViewStatus.units = [
                {name: "E1", status: UnitStatus.inService} as UnitStatusData,
                {name: "E2", status: UnitStatus.onScene} as UnitStatusData,
                {name: "T1", status: UnitStatus.outOfService} as UnitStatusData,
                {name: "M1", status: UnitStatus.transporting} as UnitStatusData,
                {name: "M2", status: UnitStatus.partialAvailable} as UnitStatusData,
            ];
        }*/

        const showOpenJobs = sessionData.board.inactiveLayers.indexOf(StationBoardInactiveLayer.openJobs) > -1;
        const leftExtraPadding = displayJob != null || (showOpenJobs && (agencyOpenJobs != null && agencyOpenJobs.length > 0)) ? Math.max(windowWidth * .33, 450) : 0;
        const rightExtraPadding = messageViewStatus.key == "shown" ? 400 : 0;

        const activeDisplayJobs = this.getActiveJobs().map(a => ({
            job: a,
            displayed: displayJob != null && a.id == displayJob.id,
        }));

        // Check where are in the day/night cycle
        const isNight = currentTime >= sessionData.board.dayEnd && currentTime < sessionData.board.dayStart;

        if (showOpenJobs && agencyOpenJobs != null) {
            mapLayers = mapLayers.filter(layer => layer.type != LayerType.agencyOpenJobs);
            mapLayers.push(new MapLayer(
                LayerType.agencyOpenJobs,
                agencyOpenJobs.filter(listJob => listJob.centroid != null).map(listJob => new JobLayerItem(
                    listJob.id,
                    listJob.centroid!!,
                    listJob.unitShortNames,
                    listJob.typeInformation,
                )),
                true,
                null,
            ));
        } else {
            mapLayers = mapLayers.filter(layer => layer.type != LayerType.agencyOpenJobs);
        }
        const seenKeys: string[] = [];
        return (
            <div id="mapPage" className="pageContent" style={{display: "flex", flexDirection: "row"}}>
                <BryxMap
                    bounds={bounds}
                    style={{height: "100%", width: "100%"}}
                    boundsOptions={{
                        paddingTopLeft: [20 + leftExtraPadding, 20],
                        paddingBottomRight: [20 + rightExtraPadding, 20],
                    }}
                    baseLayer={sessionData.board.baseLayer}>
                    {
                        BaseStationBoard.generateMapChildren(mapLayers, displayJob, sessionData, bounds)
                            .map(e => {
                                if (e.key == null) {
                                    return null;
                                }
                                if (seenKeys.indexOf(e.key!.toString()) > -1) {
                                    return null;
                                }
                                seenKeys.push(e.key!.toString());
                                return e;
                            })
                            .filter(e => e != null)
                    }
                </BryxMap>
                {activeDisplayJobs.length > 0 ? (
                    <JobOverlay
                        activeJobs={activeDisplayJobs}
                        activeTime={sessionData.board.activeTime}
                        blinkTime={sessionData.board.blinkTime}
                        now={this.state.currentTime}
                        redTime={sessionData.board.redTime}
                        showTurnout={sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.turnout)}
                        showStreetView={sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.streetView)}
                        showResponders={sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.responders)}
                        showSupplementals={sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.supplementals)}
                        showCriticalWarning={sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.criticalBanner)}
                        showDrone={sessionData.board.activeLayers.some(l => l == StationBoardActiveLayer.drone)}
                        turnoutTime={isNight ? sessionData.board.nightTurnoutTime : sessionData.board.dayTurnoutTime}
                        turnoutStart={sessionData.board.turnoutStart}
                        unitColorMap={sessionData.board.unitColors}
                    />
                ) : showOpenJobs && agencyOpenJobs != null ? (
                    <JobListOverlay openJobs={agencyOpenJobs} screenHeight={windowHeight}/>
                ) : null}
                {sessionData.drone && (
                    <div className="carousel-container-right">
                        <div style={{
                            flex: 1,
                            marginRight: "10px",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}>
                            <DroneInfoOverlay
                                altitude={sessionData.drone.altitude}
                                heading={sessionData.drone.heading}
                                speed={sessionData.drone.speed}
                                droneBattery={sessionData.drone.droneBattery}
                                rcBattery={sessionData.drone.rcBattery}
                                index={1}
                            />
                        </div>
                    </div>
                )}
                {sessionData.drone && (
                    <AlertOverlay alert={sessionData.drone.error}/>
                )}
                {sessionData.videoFeed && (
                    <JobOverlayDoorbell videoFeed={sessionData.videoFeed} />
                )}
                {activeDisplayJobs.length == 0 ? (
                    <InfoOverlay viewStatus={infoViewStatus}/>
                ) : null}
                {activeDisplayJobs.length == 0 ? (
                    <MessageOverlay viewStatus={messageViewStatus}/>
                ) : null}
            </div>
        );
    }
}
