import * as moment from "moment";
import {config} from "../config";

export class DateUtils {
    private static clockOffset = 0;

    public static setOffset(offset: number) {
        config.info(`Updating system clock offset to ${offset} ms`);
        DateUtils.clockOffset = offset;
    }

    public static duration(date1: Date, date2: Date): moment.Duration {
        return moment.duration(moment(date1).diff(date2));
    }

    private static preferredTimeFormat(includeSeconds: boolean = true): string {
        return "HH:mm:ss"; // pending StationBoardConfiguration
        /* if (includeSeconds) {
            return preferences.use24HourTime ? "HH:mm:ss" : "hh:mm:ssA";
        } else {
            return preferences.use24HourTime ? "HH:mm" : "hh:mm A";
        } */
    }

    private static preferredDateFormat(length: "short" | "medium" = "short", includeYear: boolean = true): string {
        return 'MM-DD-YYYY'; // pending StationBoardConfiguration
        /*
        if (length == "short") {
            switch (preferences.preferredDateFormat) {
                case BryxDateFormat.monthDayYear:
                    return `MM-DD${includeYear ? "-YYYY" : ""}`;
                case BryxDateFormat.dayMonthYear:
                    return `DD-MM${includeYear ? "-YYYY" : ""}`;
                case BryxDateFormat.yearMonthDay:
                    return `${includeYear ? "YYYY-" : ""}MM-DD`;
                default:
                    throw Error("Impossible case");
            }
        } else {
            switch (preferences.preferredDateFormat) {
                case BryxDateFormat.monthDayYear:
                    return `MMM D${includeYear ? " YYYY" : ""}`;
                case BryxDateFormat.dayMonthYear:
                    return `D MMM${includeYear ? " YYYY" : ""}`;
                case BryxDateFormat.yearMonthDay:
                    return `${includeYear ? "YYYY " : ""}MMM D`;
                default:
                    throw Error("Impossible case");
            }
        }*/
    }

    public static formatDateTime(date: Date): string {
        return moment(date).format(`${DateUtils.preferredTimeFormat()} [on] ${DateUtils.preferredDateFormat()}`);
    }

    public static formatTime(date: Date): string {
        return moment(date).format(DateUtils.preferredTimeFormat());
    }

    public static bryxNow(): Date {
        const systemNow = new Date();
        return new Date(systemNow.getTime() + DateUtils.clockOffset);
    }
}
