import {ParseResult, ParseUtils} from "@bryxinc/lunch";

export class Address {
    private constructor(
        public original: string,
        public street: string,
        public municipality: string | null,
        public state: string | null,
        public postalCode: string | null,
        public locationInfo: string | null,
    ) {}

    get originalWithLocationInfo(): string {
        let combinedString = this.original;
        if (this.locationInfo != null) {
            combinedString += ` - ${this.locationInfo}`;
        }
        return combinedString;
    }

    static parse(o: any): ParseResult<Address> {
        try {
            return ParseUtils.parseSuccess(new Address(
                ParseUtils.getString(o, "original"),
                ParseUtils.getString(o, "street"),
                ParseUtils.getStringOrNull(o, "municipality"),
                ParseUtils.getStringOrNull(o, "state"),
                ParseUtils.getStringOrNull(o, "postalCode"),
                ParseUtils.getStringOrNull(o, "locationInfo"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Address>(`Invalid Address: ${e.message}`);
        }
    }
}
