import * as i18n from "i18next";
import * as Qty from "js-quantities";
import * as React from "react";
import {Label, Segment} from "semantic-ui-react";
import {Responder, ResponseOptionType} from "../../models/responder";
import {BryxColors} from "../../utils/bryxColors";
import {DateUtils} from "../../utils/dateUtils";
import {normalizedQuantity} from "../../utils/functions";

interface ResponderItemViewProps {
    responder: Responder;
}

class ResponderItemView extends React.Component<ResponderItemViewProps, {}> {
    render() {
        const responder = this.props.responder;
        const distanceQuantity = responder.distance != null ? normalizedQuantity(Qty(responder.distance, "m")) : null;
        const distanceString = distanceQuantity ? i18n.t("general.xAway", {replace: {distance: distanceQuantity}}) : i18n.t("jobs.jobInfo.responders.distanceUnknown");
        const timeString = DateUtils.formatTime(responder.currentResponse.responseTime);
        return (
            <div style={{
                fontSize: "18px",
                lineHeight: "18px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "5px",
                borderLeft: `5px solid ${responder.currentResponse.responseOption.type == ResponseOptionType.positive ? BryxColors.green : BryxColors.red}`,
                marginBottom: "10px",
            }}>
                <div>
                    <span style={{display: "block", fontWeight: "bold", marginBottom: "5px"}}>{responder.name}</span>
                    <span style={{display: "block"}}>{responder.currentResponse.responseOption.text}</span>
                </div>
                <div>
                    <span style={{display: "block", color: BryxColors.gray, textAlign: "right", marginBottom: "5px"}}>{distanceString}</span>
                    <span style={{display: "block", color: BryxColors.gray, textAlign: "right"}}>{timeString}</span>
                </div>
            </div>
        );
    }
}

interface JobOverlayRespondersProps {
    responders: Responder[];
    style?: React.CSSProperties;
}

export class JobOverlayResponders extends React.Component<JobOverlayRespondersProps, {}> {
    render() {
        const {responders} = this.props;
        return (
            <Segment className="labeled" style={{overflowY: "hidden", ...this.props.style}}>
                <Label attached="top">{i18n.t("jobs.jobInfo.responders.title", {count: responders.filter(r => r.currentResponse.responseOption.type == ResponseOptionType.positive).length})}</Label>
                <div>
                    {responders.slice().sort(Responder.compare).map(r => (
                        <ResponderItemView key={r.id} responder={r}/>
                    ))}
                </div>
            </Segment>
        );
    }

}
