import * as React from "react";
import {UnitStatusData, WeatherOverlayData} from "../../models/idleOverlays";
import {UnitStatusInfoOverlay} from "./infoOverlays/unitStatusOverlay";
import {WeatherInfoOverlay} from "./infoOverlays/weatherInfoOverlay";

export type InfoOverlayViewStatus = {key: "hidden"} | {key: "shown", weather: WeatherOverlayData | null, units: UnitStatusData[] | null};

interface InfoOverlayProps {
    viewStatus: InfoOverlayViewStatus;
}

export class InfoOverlay extends React.Component<InfoOverlayProps, {}> {
    render() {
        const {viewStatus} = this.props;

        if (viewStatus.key != "shown") {
            return null;
        }

        return (
            <div className='info-container'>
                {viewStatus.weather != null ? <WeatherInfoOverlay weatherData={viewStatus.weather} /> : null}
                {viewStatus.units != null ? <UnitStatusInfoOverlay units={viewStatus.units} /> : null}
            </div>
        );
    }
}
