import * as i18n from "i18next";
import * as React from "react";

interface JobOverlayCriticalWarningProps {
    criticalWarning: string;
}

export class JobOverlayCriticalWarning extends React.Component<JobOverlayCriticalWarningProps, {}> {

    render() {
        const { criticalWarning } = this.props;

        return (
            <div style={{ backgroundColor: "#ffcc00", lineHeight: "normal", marginBottom: "-16px", borderRadius: ".28571429rem", border: "1px solid rgba(34,36,38,.15)", padding: "8px" }}>
                <span style={{fontSize: "24px", margin: "4px"}}>
                    <b>{i18n.t("jobs.jobInfo.siteSurvey.critical")}</b>: {criticalWarning}
                </span>
            </div>
        );
    }

}
