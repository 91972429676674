import * as React from "react";
import {Segment} from "semantic-ui-react";
import {MinimalJob} from "../../models/job";
import {BryxColors} from "../../utils/bryxColors";
import {DateUtils} from "../../utils/dateUtils";
import {resourceFromJobType} from "../../utils/functions";

interface JobOverlayProps {
    openJobs: MinimalJob[];
    screenHeight: number;
}

export class JobListOverlay extends React.Component<JobOverlayProps, {}> {
    private static UNIT_TEXT_SIZE = "30px";
    private static JOB_DETAILS_TEXT_SIZE = "26px";

    private static JOB_CARD_HEIGHT_APPROXIMATION = 170;

    constructor(props: JobOverlayProps, context: any) {
        super(props, context);
    }

    render() {
        const {openJobs, screenHeight} = this.props;
        if (openJobs.length == 0) {
            return null;
        }

        const jobLimit = Math.round(screenHeight / JobListOverlay.JOB_CARD_HEIGHT_APPROXIMATION);

        return (
            <div className="carousel-container">
                <div style={{flex: 1, marginRight: "10px", height: "100%", display: "flex", flexDirection: "column"}}>
                    <Segment>
                        <h2 style={{textAlign: 'center'}}>Open Jobs</h2>
                        <hr />
                        {openJobs.slice(0, jobLimit).map((job, i) => {
                            let locationDisplay = "location unknown";
                            let locationInfoDisplay = null;

                            if (job.address != null) {
                                locationDisplay = job.address.street;
                                if (job.address.locationInfo) {
                                    locationInfoDisplay = job.address.locationInfo;
                                }
                            }

                            return (
                                <div key={job.id}>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start"}}>
                                        <img src={resourceFromJobType(job.typeInformation.type)} alt="job-type" height="30px" width="30px" />
                                        <div style={{marginLeft: "15px", flex: 1}}>
                                            <span style={{fontSize: JobListOverlay.UNIT_TEXT_SIZE, lineHeight: JobListOverlay.UNIT_TEXT_SIZE}}><b>{job.unitShortNames.join(", ")}</b> - {job.synopsis}</span>
                                            <hr style={{margin: "0.5em 0"}} />
                                            <div style={{fontSize: JobListOverlay.JOB_DETAILS_TEXT_SIZE, marginBottom: "6px", lineHeight: JobListOverlay.JOB_DETAILS_TEXT_SIZE}}>
                                                <b>{locationDisplay}</b>{locationInfoDisplay ? ' - ' + locationInfoDisplay : undefined}
                                            </div>
                                            <div style={{fontSize: JobListOverlay.JOB_DETAILS_TEXT_SIZE, color: BryxColors.lightGray}}>
                                                {DateUtils.formatDateTime(job.creationTime)}
                                            </div>
                                        </div>
                                    </div>
                                    {i < (jobLimit - 1) ? <hr /> : null}
                                </div>
                            );
                        })}
                    </Segment>
                </div>
            </div>
        );
    }
}
