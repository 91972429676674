import {DivIcon, FitBoundsOptions, Icon, latLngBounds, LatLngBoundsExpression} from "leaflet";
import * as React from 'react';
import {LayerGroup, LayersControl, Map, TileLayer} from "react-leaflet";
import {StationBoardBaseLayer} from "../models/board";
import {Hydrant} from "../models/hydrant";
import {JobType} from "../models/jobTypeInformation";
import {MapClient, MapClientType} from "../models/mapClient";
import {BryxApi} from "../utils/bryxApi";
import {BryxLocal} from "../utils/bryxLocal";

interface BryxMapProps {
    animate?: boolean;
    baseLayer: StationBoardBaseLayer;
    bounds?: LatLngBoundsExpression;
    boundsOptions?: FitBoundsOptions;
    controllable?: boolean;
    className?: string;
    style?: React.CSSProperties;
    useFlyTo?: boolean;
    id?: string;
    onClickFullscreen?: () => void;
    mapLayers?: BryxMapLayer[];
    onUpdateBounds?: (bounds: LatLngBoundsExpression) => void;
}

export interface BryxMapLayer {
    name: string;
    label: string;
    defaultChecked: boolean;
    elements: JSX.Element[];
}

export class BryxMap extends React.Component<BryxMapProps, {}> {
    public static readonly genericIcon = new Icon({
        iconUrl: "/resources/assets/generic_pin.svg",
        iconSize: [34.5, 67.5],
        iconAnchor: [17.25, 67.5],
    });

    public static readonly stationIcon = new Icon({
        iconUrl: "/resources/assets/station_pin.svg",
        iconSize: [34.5, 67.5],
        iconAnchor: [17.25, 67.5],
    });

    public static readonly fireIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/fire_pin.svg",
        iconSize: [34.5, 67.5],
        iconAnchor: [17.25, 67.5],
    });

    public static readonly emsIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/ems_pin.svg",
        iconSize: [34.5, 67.5],
        iconAnchor: [17.25, 67.5],
    });

    public static readonly infoIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/info_pin.svg",
        iconSize: [34.5, 67.5],
        iconAnchor: [17.25, 67.5],
    });

    public static readonly waterIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/water_pin.svg",
        iconSize: [34.5, 67.5],
        iconAnchor: [17.25, 67.5],
    });

    public static readonly policeIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/police_pin.svg",
        iconSize: [34.5, 67.5],
        iconAnchor: [17.25, 67.5],
    });

    public static readonly myLocationIcon = new Icon({
        iconUrl: "/resources/assets/my_location.gif",
        iconSize: [15, 15],
        iconAnchor: [7.5, 7.5],
    });

    public static readonly droneIcon = new Icon({
        iconUrl: "/resources/assets/drone_pin.svg",
        iconSize: [50, 50],
        iconAnchor: [7.5, 7.5],
    });

    private static readonly unitedStatesBounds = latLngBounds([24.7433195, -124.7844079], [49.3457868, -66.9513812]);

    private static readonly ACCESS_TOKEN = "pk.eyJ1IjoidGVicm93biIsImEiOiIzb0xlTjlzIn0.qRGtH1Q-ZAViez0fTPX9fg";
    private static readonly MB_ATTRIBUTION = 'Data &copy; <a href="http://bryx.com">Bryx, Inc.</a> | Imagery from <a href="http://mapbox.com/about/maps/">MapBox</a> | Map &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';

    static jobTypeToIcon(type: JobType): Icon {
        switch (type) {
            case JobType.fire:
                return BryxMap.fireIcon;
            case JobType.ems:
                return BryxMap.emsIcon;
            case JobType.info:
                return BryxMap.infoIcon;
            case JobType.water:
                return BryxMap.waterIcon;
            case JobType.police:
                return BryxMap.policeIcon;
        }
    }

    static hydrantIconCache: {[index: string]: DivIcon} = {};

    static hydrantIcon(hydrant: Hydrant): DivIcon {
        const cleanedSize = (hydrant.mainSize && hydrant.mainSize.toString() || "").slice(0, 2);
        const cacheKey = `hydrant:${hydrant.color}:${cleanedSize}`;
        const cachedIcon = BryxMap.hydrantIconCache[cacheKey];
        if (cachedIcon != null) {
            return cachedIcon;
        }
        const icon = new DivIcon({
            iconUrl: "/resources/assets/hydrant_pin.svg",
            iconSize: [30, 45],
            iconAnchor: [15, 45],
            className: "hydrantIcon",
            html: `<img src='${BryxApi.getHydrantSvgUrl(hydrant.color)}' height="45px" width="30px"/><span style='position: absolute; bottom: 2px; left: 0; width: 30px; font-size: 16px; text-align: center; color: white;'>${cleanedSize}</span>`,
        });
        BryxMap.hydrantIconCache[cacheKey] = icon;
        return icon;
    }

    static clientIconCache: {[index: string]: DivIcon} = {};

    static mapClientIcon(mapClient: MapClient, transparent = false): DivIcon {
        const cacheKey = `${mapClient.id}:${transparent}`;
        const cachedIcon = BryxMap.clientIconCache[cacheKey];
        if (cachedIcon != null) {
            return cachedIcon;
        }
        const topOffset = mapClient.initials.length > 3 ? "32px" : "28px";
        const textSize = mapClient.initials.length > 3 ? "10px" : "15px";
        const isInvert: boolean | null = BryxLocal.getItem("invertClientPins");
        const invertSuffix = isInvert == true ? "_inv" : "";
        const fontColor = isInvert == true ? "white" : "black";
        if (mapClient.info.type == MapClientType.user) {
            const icon = new DivIcon({
                iconAnchor: [18, 67.5],
                className: "userIcon",
                html: `<img src='/resources/assets/client_map_pins/user_pin${invertSuffix}.png' ${transparent ? "style='opacity: 0.6'" : ""} height="67.5px" width="36px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 36px; text-align: center; color: ${fontColor}; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
            });
            BryxMap.clientIconCache[cacheKey] = icon;
            return icon;
        } else {
            const icon = new DivIcon({
                iconAnchor: [18, 67.5],
                className: "truckIcon",
                html: `<img src='/resources/assets/client_map_pins/truck_pin${invertSuffix}.png' ${transparent ? "style='opacity: 0.6'" : ""} height="67.5px" width="36px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 36px; text-align: center; color: ${fontColor}; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
            });
            BryxMap.clientIconCache[cacheKey] = icon;
            return icon;
        }
    }

    static getBaseLayerURL(baseLayer: StationBoardBaseLayer): string {
        let baseLayerId;
        let user;
        switch (baseLayer) {
            case StationBoardBaseLayer.streets:
                baseLayerId = "streets-v11";
                user = "mapbox";
                break;
            case StationBoardBaseLayer.dark:
                baseLayerId = "dark-v10";
                user = "mapbox";
                break;
            case StationBoardBaseLayer.highContrast:
                baseLayerId = "ckc1zfk596h3a1ho6g871izj5";
                user = "tebrown";
                break;
            case StationBoardBaseLayer.hybrid:
                baseLayerId = "satellite-streets-v11";
                user = "mapbox";
                break;
            case StationBoardBaseLayer.satellite:
                baseLayerId = "satellite-v9";
                user = "mapbox";
                break;
        }

        return `https://api.mapbox.com/styles/v1/${user}/${baseLayerId}/tiles/{z}/{x}/{y}?access_token={accessToken}`;
    }

    render() {
        return (
            <Map maxZoom={20}
                 timeout={30000}
                 zoomControl={this.props.controllable ? undefined : false}
                 dragging={this.props.controllable ? undefined : false}
                 doubleClickZoom={this.props.controllable ? undefined : false}
                 scrollWheelZoom={this.props.controllable ? undefined : false}
                 zoomAnimation={false}
                 className="bryxMap"
                 {...this.props}
                 bounds={this.props.bounds || BryxMap.unitedStatesBounds}
                 whenReady={() => {
                     this.setState({viewportStatus: { key: "pending" }});
                 }}>
                <TileLayer
                    url={BryxMap.getBaseLayerURL(this.props.baseLayer)}
                    accessToken={BryxMap.ACCESS_TOKEN}
                    attribution={BryxMap.MB_ATTRIBUTION}
                    tileSize={512}
                    zoomOffset={-1}
                />
                {this.props.mapLayers != null ? (
                    <LayersControl position="topright">
                        {this.props.mapLayers.map(layer => (
                            <LayersControl.Overlay key={layer.name} name={layer.label} checked={layer.defaultChecked}>
                                <LayerGroup key={layer.name}>
                                    {layer.elements}
                                </LayerGroup>
                            </LayersControl.Overlay>
                        ))}
                    </LayersControl>
                ) : null}
                {this.props.children}
            </Map>
        );
    }
}
