import {RouteComponentProps} from "react-router";
import {BaseStationBoard, StationBoardState} from "../components/stationBoard";
import {DateUtils} from "../utils/dateUtils";

export class StationBoard extends BaseStationBoard {

    screensaverDataTimer: any = null;

    constructor(props: RouteComponentProps<{}>, context: any) {
        super(props, context);
    }

    wakeDisplay(): void {
        if (!(window as any).electron || !(window as any).electron.ipcRenderer) {
            console.error("Attempted to wake display but electron IPC is not initialized");
            return;
        }
        (window as any).electron.ipcRenderer.send("activateDisplay");
    }

    sendData(data: string): void {
        if (!(window as any).electron || !(window as any).electron.ipcRenderer) {
            console.error("Attempted to send data but electron IPC is not initialized");
            return;
        }
        (window as any).electron.ipcRenderer.send("data", data);
    }

    sendHeartBeat(): void {
        (window as any).electron.ipcRenderer.send("heartbeat");
    }

    sendScreensaverData(): void {
        if (!this.state || !this.state.sessionData) {
            console.warn("state or state.sessionData unset at ", new Date().toString());
            return;
        }

        const boardData = this.state.sessionData.board;
        const now = DateUtils.bryxNow();
        const isNight = now >= boardData.dayEnd && now < boardData.dayStart;

        this.sendData(JSON.stringify({
            screensaver: {
                mode: isNight ? boardData.screenSaverModeNight : boardData.screenSaverModeDay,
                timeout: boardData.screenSaverTimeout,
            },
        }));

        this.screensaverDataTimer = setTimeout(this.sendScreensaverData,
            isNight ? now.valueOf() - boardData.dayStart.valueOf()
                : now.valueOf() - boardData.dayEnd.valueOf());
    }

    onInitialized(): void {
        if (!this.state.sessionData) {
            return;
        }

        this.sendScreensaverData();

        const boardData = this.state.sessionData.board;
        const now = DateUtils.bryxNow();
        const isNight = now >= boardData.dayEnd && now < boardData.dayStart;

        this.screensaverDataTimer = setTimeout(this.sendScreensaverData,
            isNight ? now.valueOf() - boardData.dayStart.valueOf()
                : now.valueOf() - boardData.dayEnd.valueOf());

        if ((window as any).electron && (window as any).electron.ipcRenderer) {
            setInterval(this.sendHeartBeat, 10000);
        }
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}>>, prevState: Readonly<StationBoardState>, prevContext: any): void {
        const prevActive = prevState.displayJob;
        const currentActive = this.state.displayJob;

        if ((prevActive != null && currentActive != null && prevActive.id != currentActive.id) || currentActive != null) {
            // config.debug("Sending CEC activation");
            this.wakeDisplay();
        }
    }

}
