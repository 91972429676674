import {ParseResult, ParseUtils} from "@bryxinc/lunch";

export class Eula {
    private constructor(
        public eulaVersion: number,
        public eula: string,
    ) {}

    static parse(o: any): ParseResult<Eula> {
        try {
            return ParseUtils.parseSuccess(new Eula(
                ParseUtils.getNumber(o, "eulaVersion"),
                ParseUtils.getString(o, "eula"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Eula>(`Invalid Eula Model: ${e.message}`);
        }
    }
}
