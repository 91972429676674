import {ParseResult, ParseUtils} from "@bryxinc/lunch";

export enum JobType {
    fire, ems, water, info, police,
}

export class JobTypeInformation {
    private constructor(
        public type: JobType,
        public description: string | null,
        public code: string,
        public section: string | null,
    ) {}

    static parse(o: any): ParseResult<JobTypeInformation> {
        try {
            return ParseUtils.parseSuccess(new JobTypeInformation(
                ParseUtils.getEnum(o, "type", JobType, JobType.info),
                ParseUtils.getStringOrNull(o, "description"),
                ParseUtils.getString(o, "code"),
                ParseUtils.getStringOrNull(o, "section"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<JobTypeInformation>(`Invalid JobTypeInformation Model: ${e.message}`);
        }
    }
}
