import {config} from "../config";
import {Disposition, ListJob} from "../models/job";
import {JobsListServerUpdate} from "../models/jobsListUpdate";
import {BryxApi} from "./bryxApi";

export interface JobManagerUpdateObserver {
    jobManagerDidUpdateJobs(openJobs: ListJob[], additionsPossible: boolean): void;
    jobManagerDidFail(msg: string): void;
}

export class JobManager {
    static shared = new JobManager();

    private static readonly jobsListSubscriptionKey = "jobManager-list";

    private observers: JobManagerUpdateObserver[] = [];

    openJobs: ListJob[] | null = null;

    startLoadingJobs() {
        BryxApi.subscribeToNewJobs(JobManager.jobsListSubscriptionKey, "reset", result => {
            if (result.success == true) {
                BryxApi.changeJobListSubscription(JobManager.jobsListSubscriptionKey, "resume", false);
                switch (result.value.key) {
                    case "replace":
                        const openJobs = result.value.openJobs.sort(ListJob.compare);
                        this.openJobs = openJobs;
                        this.observers.forEach(o => o.jobManagerDidUpdateJobs(openJobs, true));
                        /* Ignore closed jobs. */
                        break;
                    case "fastForward":
                        this.applyUpdates(result.value.updates);
                        break;
                    case "update":
                        this.applyUpdates([result.value.update]);
                        break;
                }
            } else {
                config.warn(`Jobs list websocket failed: ${result.debugMessage}`);
                this.observers.forEach(o => o.jobManagerDidFail(result.message));
            }
        });
    }

    public registerObserver(observer: JobManagerUpdateObserver) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: JobManagerUpdateObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }

    private applyUpdates(updates: JobsListServerUpdate[]) {
        const openJobs = this.openJobs;
        if (openJobs == null) {
            config.warn("Failed to apply job manager updates before list init");
            return;
        }
        let openJobsChanged = false;
        let openAdditionsPossible = false;
        updates.forEach(update => {
            switch (update.key) {
                case "new":
                    config.debug(`Processing 'new' jobs list update: job@${update.job.id}`);
                    const existingOpenJobIndex = openJobs.map(j => j.id).indexOf(update.job.id);
                    if (existingOpenJobIndex != -1) {
                        openJobs[existingOpenJobIndex] = update.job;
                    } else {
                        openJobs.push(update.job);
                        openAdditionsPossible = true;
                    }
                    openJobsChanged = true;
                    break;
                case "old":
                    config.debug(`Processing 'old' jobs list update: job@${update.jobId}`);
                    const existingJobForOld = openJobs.filter(j => j.id == update.jobId)[0];
                    if (existingJobForOld != null) {
                        existingJobForOld.disposition = Disposition.old;
                        openJobsChanged = true;
                    }
                    break;
                case "closed":
                    config.debug(`Processing 'closed' jobs list update: job@${update.job.id}`);
                    const previouslyOpenJobIndex = openJobs.map(j => j.id).indexOf(update.job.id);
                    if (previouslyOpenJobIndex != -1) {
                        openJobs.splice(previouslyOpenJobIndex, 1);
                        openJobsChanged = true;
                    }
                    break;
                case "assignments":
                    config.debug(`Processing 'assignments' jobs list update: job@${update.jobId}`);
                    const existingJobForAssignment = openJobs.filter(j => j.id == update.jobId)[0];
                    if (existingJobForAssignment != null) {
                        existingJobForAssignment.unitShortNames = update.unitShortNames;
                        openJobsChanged = true;
                    }
                    break;
                case "supplemental":
                    config.debug(`Processing 'supplemental' jobs list update: job@${update.jobId}`);
                    const existingJobForSupp = openJobs.filter(j => j.id == update.jobId)[0];
                    if (existingJobForSupp != null) {
                        existingJobForSupp.supplementals.push(update.supplemental);
                        openJobsChanged = true;
                    }
                    break;
                case "responder":
                    config.debug(`Processing 'responder' jobs list update: job@${update.jobId}`);
                    const existingJobForResponder = openJobs.filter(j => j.id == update.jobId)[0];
                    if (existingJobForResponder != null) {
                        const existingResponderIndex = existingJobForResponder.responders.map(r => r.id).indexOf(update.responder.id);
                        if (existingResponderIndex != -1) {
                            existingJobForResponder.responders[existingResponderIndex] = update.responder;
                        } else {
                            existingJobForResponder.responders.push(update.responder);
                        }
                        openJobsChanged = true;
                    }
                    break;
            }
        });

        // Ensure both lists are sorted, if required, before notifying anyone of anything.
        if (openJobsChanged) {
            openJobs.sort(ListJob.compare);
            this.observers.forEach(o => o.jobManagerDidUpdateJobs(openJobs, openAdditionsPossible));
        }

        // Acknowledge updates
        if (updates.length != 0) {
            BryxApi.acknowledgeJobsListUpdates(updates.map(u => u.id), result => {
                if (result.success == true) {
                    config.debug(`Successfully acknowledged ${updates.length} jobs list updates.`);
                } else {
                    // It's OK for acks to fail. We will just get them on the next reconnection.
                    config.warn(`Failed to acknowledge updates: ${result.debugMessage}`);
                }
            });
        }

    }

    static stopLoadingJobs() {
        BryxApi.unsubscribe(JobManager.jobsListSubscriptionKey);
    }

    static refreshJobs() {
        BryxApi.changeJobListSubscription(JobManager.jobsListSubscriptionKey, "reset", true);
    }

    reset(resubscribe: boolean) {
        this.openJobs = null;
        if (resubscribe) {
            JobManager.refreshJobs();
        } else {
            JobManager.stopLoadingJobs();
        }
    }
}
