import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {Point} from "geojson";

export class Station {
    public constructor(
        public id: string,
        public hq: boolean,
        public location: Point,
        public name: string,
        public address: string,
    ) {}

    static parse(o: any): ParseResult<Station> {
        try {
            return ParseUtils.parseSuccess(new Station(
                ParseUtils.getString(o, "id"),
                ParseUtils.getBoolean(o, "hq"),
                o['location'],
                ParseUtils.getString(o, "name"),
                ParseUtils.getString(o, "address"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Station>(`Invalid AgencyStation Model: ${e.message}`);
        }
    }
}
