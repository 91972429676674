import {config} from "../config";
import {
    CurrentWeatherForecast,
    WeatherForecast,
    WeatherForecastType,
    WeatherUpdate,
} from "../models/idleOverlays";
import {ApiResult, BryxApi} from "./bryxApi";

export interface WeatherManagerObserver {
    weatherManagerDidUpdateCurrentWeather(newForecast: CurrentWeatherForecast): void;
    weatherManagerDidUpdateForecast(newForecast: WeatherForecast[]): void;
}

export type WeatherSubscriptionParameters = {
    current: boolean,
    forecast: WeatherForecastType,
};

export class WeatherManager {
    static shared = new WeatherManager();

    private static readonly weatherSubscriptionKey = "sb-weather";

    private subscriptionParams: WeatherSubscriptionParameters | null = null;
    private subscribed = false;
    private observers: WeatherManagerObserver[] = [];

    public setParams(newParams: WeatherSubscriptionParameters) {
        if (this.subscriptionParams != null && this.subscriptionParams.current == newParams.current && this.subscriptionParams.forecast == newParams.forecast) {
            return;
        }
        this.subscriptionParams = newParams;
        if (this.subscribed) {
            BryxApi.changeWeatherSubscription(WeatherManager.weatherSubscriptionKey, newParams.current, newParams.forecast);
        } else {
            this.subscribed = true;
            BryxApi.subscribeToWeather(WeatherManager.weatherSubscriptionKey, newParams.current, newParams.forecast, this.onWeatherUpdate.bind(this));
        }
    }

    public reset() {
        BryxApi.unsubscribe(WeatherManager.weatherSubscriptionKey);
        this.subscribed = false;
        this.subscriptionParams = null;
    }

    private onWeatherUpdate(result: ApiResult<WeatherUpdate>) {
        if (result.success == true) {
            switch (result.value.type) {
                case "initialData":
                    const newCurrent = result.value.current;
                    const newForecast = result.value.forecast;
                    this.observers.forEach(o => {
                        if (newCurrent != null) {
                            o.weatherManagerDidUpdateCurrentWeather(newCurrent);
                        }
                        if (newForecast != null) {
                            o.weatherManagerDidUpdateForecast(newForecast);
                        }
                    });
                    break;
                case "current":
                    const current = result.value.current;
                    this.observers.forEach(o => o.weatherManagerDidUpdateCurrentWeather(current));
                    break;
                case "forecast":
                    const forecast = result.value.forecast;
                    this.observers.forEach(o => o.weatherManagerDidUpdateForecast(forecast));
                    break;
            }
        } else {
            config.warn(`Weather websocket failed: ${result.debugMessage}`);
        }
    }

    // MapManagerObservers

    public registerObserver(observer: WeatherManagerObserver) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: WeatherManagerObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
