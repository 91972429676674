import * as React from 'react';
import {Popup} from 'semantic-ui-react';

interface KeyValueViewProps {
    keyNode: React.ReactNode;
    valueNode: React.ReactNode;
    keyWidth?: string;
    keyTooltip?: string;
}

export class KeyValueView extends React.Component<KeyValueViewProps, any> {
    render() {
        const keyWidth = this.props.keyWidth || "100px";
        const key = <div style={{color: "#b0b0b0", minWidth: keyWidth, width: keyWidth, marginRight: "8px", textAlign: "right"}}>{this.props.keyNode}</div>;

        return (
            <div style={{paddingBottom: "8px", display: "flex", flexDirection: "row"}}>
                {this.props.keyTooltip == null ?
                    key : (
                    <Popup trigger={key} content={this.props.keyTooltip} position="top center"/>
                )}
                <div>{this.props.valueNode}</div>
            </div>
        );
    }
}
