import * as React from "react";
import {Loader, Segment} from "semantic-ui-react";
import {config} from "../../config";
import {ListJob} from "../../models/job";
import {StreetViewResult} from "../../models/streetViewResult";

interface JobOverlayStreetViewProps {
    job: ListJob;
}

interface JobOverlayStreetViewState {
    result: {key: "loading", jobId: string} | {key: "ready", result: StreetViewResult};
}

export class JobOverlayStreetView extends React.Component<JobOverlayStreetViewProps, JobOverlayStreetViewState> {
    constructor(props: JobOverlayStreetViewProps, context: any) {
        super(props, context);
        this.state = {
            result: {key: "loading", jobId: props.job.id},
        };
    }

    componentDidMount(): void {
        this.updateJob(this.props.job);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<JobOverlayStreetViewProps>, nextContext: any): void {
        if (this.props.job.id != nextProps.job.id) {
            this.updateJob(nextProps.job);
        }
    }

    private updateJob(job: ListJob) {
        this.setState({result: {key: "loading", jobId: job.id}});
        job.getStreetViewResult(result => {
            this.setState(prevState => {
                // If a request takes a very long time, we might be looking at a different job by now.
                if (prevState.result.key == "loading" && prevState.result.jobId == job.id) {
                    return {result: {key: "ready", result: result}};
                } else {
                    return {};
                }
            });
            if (result.key == "error") {
                config.warn(`Failed to load street view: ${result.message}`);
            }
        });
    }

    render() {
        const {result} = this.state;
        if (result.key == "loading") {
            return (
                <Segment className="flexCenterRow" style={{height: "330px"}}>
                    <Loader inline active/>
                </Segment>
            );
        } else if (result.result.key != "available") {
            return null;
        }

        return (
            <div>
                <img
                    src={result.result.imageUrl}
                    alt="street view image"
                    style={{display: "block", width: "100%", height: "auto", margin: "auto", borderRadius: ".28571429rem"}}/>
            </div>
        );
    }
}
