import {config} from "../config";
import {
    IdleLayersInitialData,
    IdleLayersUpdate,
    IdleLayersUpdateClosedJob,
    IdleLayersUpdateJob, IdleLayersUpdateNewJob,
} from "../models/idleLayersUpdate";
import {MinimalJob} from "../models/job";
import {ApiResult, BryxApi} from "./bryxApi";

export interface IdleLayerManagerObserver {
    idleLayerManagerDidUpdateOpenJobs(jobs: MinimalJob[]): void;
    idleLayerManagerDidUpdateJob(job: MinimalJob): void;
    idleLayerManagerDidAddJob(newJob: MinimalJob): void;
    idleLayerManagerDidCloseJob(id: string): void;
}

export type IdleLayerSubscriptionParameters = {
    layers: ['openJobs'];
};

export class IdleLayerManager {
    static shared = new IdleLayerManager();

    private static readonly idleLayersSubscriptionKey = "sb-idle";

    private subscriptionParams: IdleLayerSubscriptionParameters | null = null;
    private subscribed = false;
    private observers: IdleLayerManagerObserver[] = [];

    public setParams(newParams: IdleLayerSubscriptionParameters) {
        this.subscriptionParams = newParams;
        if (this.subscribed) {
            BryxApi.changeIdleLayersSubscription(IdleLayerManager.idleLayersSubscriptionKey, newParams.layers);
        } else {
            this.subscribed = true;
            BryxApi.subscribeToIdleLayers(IdleLayerManager.idleLayersSubscriptionKey, newParams.layers, this.onIdleLayersUpdate.bind(this));
        }
    }

    private onIdleLayersUpdate(result: ApiResult<IdleLayersUpdate>) {
        if (result.success == true) {
            switch (result.value.type) {
                case "initialData":
                    this.observers.forEach(o => o.idleLayerManagerDidUpdateOpenJobs((result.value as IdleLayersInitialData).jobs));
                    break;
                case "newJob":
                    const updateNew = result.value as IdleLayersUpdateNewJob;
                    this.observers.forEach(o => o.idleLayerManagerDidAddJob(updateNew.job));
                    break;
                case "closedJob":
                    const updateClosed = result.value as IdleLayersUpdateClosedJob;
                    this.observers.forEach(o => o.idleLayerManagerDidCloseJob(updateClosed.closed));
                    break;
                case "updatedJob":
                    const updatedJob = result.value as IdleLayersUpdateJob;
                    this.observers.forEach(o => o.idleLayerManagerDidUpdateJob(updatedJob.job));
                    break;
                default:
                    break;
            }
        } else {
            config.warn(`Idle Layers websocket failed: ${result.debugMessage}`);
        }
    }

    // IdleLayerManagerObservers

    public registerObserver(observer: IdleLayerManagerObserver) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: IdleLayerManagerObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
