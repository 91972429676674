import {v4 as uuidgen} from "uuid";
import {config} from "../config";
import {Auth as AuthModel} from "../models/auth";

export class BryxLocal {
    private static readonly prefix: string = `com.bryx.`;
    private static readonly globalPrefix: string = "global.bryx.";
    private static readonly requiredKeysForSignIn: string[] = [ 'apiKey' ];

    private static get(key: string, prefix: string = BryxLocal.prefix): any {
        const item = localStorage.getItem(prefix + key);
        return item ? JSON.parse(item) : null;
    }

    private static set(key: string, value: any, prefix: string = BryxLocal.prefix) {
        localStorage.setItem(prefix + key, JSON.stringify(value));
    }

    /*
    // as of gutting, this is unused:
    private static remove(key: string, prefix: string = BryxLocal.prefix) {
        localStorage.removeItem(prefix + key);
    }
    */

    public static getBryxItems(): any {
        const bryxItems: any = {};

        Object.keys(localStorage)
            .filter(key => key.indexOf(BryxLocal.prefix) == 0 || key.indexOf(BryxLocal.globalPrefix) == 0)
            .forEach(key => { bryxItems[key] = localStorage.getItem(key); });

        bryxItems.appVersion = config.version;

        return bryxItems;
    }

    public static getItem<T>(key: string): T | null {
        return this.get(key) as T | null;
    }

    public static setItem<T>(key: string, value: T) {
        return this.set(key, value);
    }

    public static clear() {
        Object.keys(localStorage)
            .filter(key => key.indexOf(BryxLocal.prefix) == 0)
            .forEach(key => { localStorage.removeItem(key); });
    }

    public static initializeFromAuthModel(authModel: AuthModel): void {
        this.set('apiKey', authModel.apiKey);
    }

    public static getDeviceId(): string {
        let currentId = this.get("deviceId", BryxLocal.globalPrefix);
        if (currentId == null) {
            currentId = uuidgen();
            this.set("deviceId", currentId, BryxLocal.globalPrefix);
        }
        return currentId;
    }

    public static getForceDeviceSupport(): boolean | null {
        return this.get("forceDeviceSupport");
    }

    public static setForceDeviceSupport(force: boolean) {
        this.set("forceDeviceSupport", force);
    }

    public static getApiKey(): string | null {
        return this.get('apiKey');
    }

    public static getShowEula(): boolean | null {
        return this.get('showEula');
    }

    public static isSignedIn(): boolean {
        return this.requiredKeysForSignIn.every(k => BryxLocal.get(k) != null);
    }
}
