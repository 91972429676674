import {Icon} from "leaflet";
import 'leaflet-rotatedmarker';
import * as React from 'react';
import {Marker} from "react-leaflet";

interface DroneMarkerProps {
  location: L.LatLng;
  altitude: number | null;
  speed: number | null;
  heading: string | null;
}

export default class DroneMarker extends React.Component<any> {
  private static getDirectionalDroneIcon() {
    return new Icon({
      iconUrl: "/resources/assets/drone_pin_arrow.svg",
      iconSize: [50, 50],
      iconAnchor: [7.5, 7.5],
    });
  }

  render() {
    return (
      <Marker
        key="drone-position"
        icon={DroneMarker.getDirectionalDroneIcon()}
        position={this.props.location}
        zIndexOffset={650}
        ref={(r) => {
          if (r) {
            r.leafletElement.openPopup();
            if (this.props.heading != null) {
              r.leafletElement.setRotationOrigin('center');
              r.leafletElement.setRotationAngle(parseInt(this.props.heading, 10));
            }
          }
        }}
      />
    );
  }
}
