import * as i18n from "i18next";
import {Options} from "i18next";
import * as XHR from 'i18next-xhr-backend';
import * as QueryParams from 'query-string';
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Redirect} from "react-router-dom";
import {Button, Header, Icon, List, Message, Segment} from "semantic-ui-react";
import {ContactSupportModal} from "./components/contactSupportModal";
import {config} from "./config";
import {Auth} from "./models/auth";
import {ApiResult, BryxApi} from "./utils/bryxApi";
import {BryxLocal} from "./utils/bryxLocal";
import {CodeWebSocket} from "./utils/codeWebSocket";
import {nullIfBlank} from "./utils/functions";

interface LoginState {
    status: LoginStatus;
    overlayContent: "none" | "contactSupport";
    token: string | null;
    code: string | null;
    initializing: boolean;
}

type LoginProps = RouteComponentProps<{}>;

interface LoginQueryParams {
    token: string | null;
    invert: boolean | null;
    sound: boolean | null;
}

type LoginStatus =
    { key: "ready", messageContent: "none" | "forceSignedOut" } |
    { key: "loading" } |
    { key: "success", redirectLocation: string } |
    { key: "error", alertMessage: string };

export class Login extends React.Component<LoginProps, LoginState> {

    private codeSocket: CodeWebSocket | null;
    private heartbeatInterval: NodeJS.Timer | null;

    constructor(props: LoginProps, context: any) {
        super(props, context);

        const params = Login.getQueryParams(this.props);
        let status: LoginStatus;
        if (BryxLocal.isSignedIn() && params.token == null) {
            status = {key: "success", redirectLocation: this.getRedirectLocation()};
        } else {
            i18n
                .use(XHR)
                .init({
                    lng: BryxLocal.getItem<string>("locale") || "en-US",
                    backend: {
                        loadPath: "/resources/locales/{{lng}}.json",
                    },
                    fallbackLng: "en",
                } as Options, () => {
                    this.setState({
                        initializing: false,
                    });
                });

            if (this.props.location.state) {
                switch (this.props.location.state.type) {
                    case "forced":
                        status = {key: "ready", messageContent: "forceSignedOut"};
                        break;
                    default:
                        status = {key: "ready", messageContent: "none"};
                }
            } else {
                status = {key: "ready", messageContent: "none"};
            }
        }

        if (config.scuBuild) {
            this.codeSocket = new CodeWebSocket(this.onCode.bind(this), this.onConfig.bind(this));
        }

        this.state = {
            token: params.token,
            overlayContent: "none",
            initializing: true,
            code: null,
            status: status,
        };
    }

    private static getQueryParams(props: LoginProps): LoginQueryParams {
        const params = QueryParams.parse(props.location.search);
        const queryParams: LoginQueryParams = {
            token: null,
            invert: null,
            sound: null,
        };
        if (params != null && params['t'] != null) {
            queryParams.token = nullIfBlank(decodeURIComponent(params['t']));
        }
        if (params != null && params['invert'] != null) {
            queryParams.invert = nullIfBlank(decodeURIComponent(params['invert'])) == "true";
        }
        if (params != null && params['sound'] != null) {
            queryParams.sound = nullIfBlank(decodeURIComponent(params['sound'])) == "true";
        }
        return queryParams;
    }

    componentDidMount() {
        if (BryxLocal.isSignedIn() && this.state.token != null) {
            config.info(`Switching from existing sign-in to token ${this.state.token}`);
            BryxApi.signOut(result => {
                if (result.success == true) {
                    config.info("Signed out successfully");
                } else {
                    config.warn(`Failed to sign out but switching tokens anyway: ${result.message}`);
                }
                this.submitCredentials();
            });
        } else if (!BryxLocal.isSignedIn() && this.state.token != null) {
            this.submitCredentials();
        }
    }

    private getRedirectLocation(): string {
        if (this.props.location.state && this.props.location.state.from) {
            return this.props.location.state.from;
        } else {
            const params = Login.getQueryParams(this.props);
            return `/${params.invert || params.sound ? `?${params.invert ? `invert=true${params.sound ? "&sound=true" : ''}` : params.sound ? "sound=true" : ''}` : ''}`;
        }
    }

    private authCallback(result: ApiResult<Auth>) {
        if (result.success == true) {
            const redirectLocation = this.getRedirectLocation();
            this.setState({
                status: {
                    key: "success",
                    redirectLocation: redirectLocation,
                },
            });
            config.info(`User successfully signed in, redirecting to ${redirectLocation}`);
        } else {
            this.setState({
                status: {
                    key: "error",
                    alertMessage: result.message,
                },
            });
            config.warn(`User failed to sign in: ${result.debugMessage}`);
        }
    }

    private submitCredentials(): void {
        const {token} = this.state;

        if (token == null) {
            return;
        }

        BryxApi.signIn(token, this.authCallback.bind(this));
    }

    onCode(code: string): void {
        if (this.state.status.key != "ready") {
            return;
        }

        this.setState({
            code: code,
        });
    }

    onConfig(id: string): void {
        this.setState({
            token: id,
            status: {key: "loading"},
        }, () => this.submitCredentials());
    }

    render() {
        const scuBuild = config.scuBuild;
        const dev = config.serverType == "dev";

        if (!this.heartbeatInterval && scuBuild && (window as any).electron && (window as any).electron.ipcRenderer) {
            config.info("Setting up heartbeats for scuBuild");
            this.heartbeatInterval = setInterval(() => (window as any).electron.ipcRenderer.send("heartbeat"), 10000);
        }

        if (scuBuild && (window as any).electron == undefined && !dev) {
            return <Redirect to="/" />;
        }

        if (this.state.status.key == "success") {
            return <Redirect to={this.state.status.redirectLocation}/>;
        }

        if (this.state.initializing) {
            return null;
        }

        let alertString = null;
        let alertType: null | "negative" | "warning" = null;

        if (this.state.status.key == "error") {
            alertString = this.state.status.alertMessage;
            alertType = "negative";
        } else if (this.state.status.key == "ready" && this.state.status.messageContent === "forceSignedOut") {
            alertString = i18n.t("login.forceSignedOut");
            alertType = "negative";
        }

        const alertMessage = alertString && alertType ? (
            <Message negative={alertType == "negative"}
                     content={alertString}/>
        ) : null;

        return (
            <div style={{height: "100%"}}>
                <div className="logoAndText">
                    <img src={"/resources/assets/logo_white.png"} style={{width: "30px"}} alt="Bryx"/> <span className="siteName">{i18n.t(`branding.${scuBuild ? 'scu' : 'web'}.siteName`)}</span>
                </div>
                {!scuBuild ? (
                    <div id="contactSupportDiv">
                        <a id="contactSupportLink"
                           style={{textDecoration: "none"}}
                           onClick={() => this.setState({
                               overlayContent: "contactSupport",
                           })}>
                            {i18n.t("contactSupport.header")}
                        </a>
                    </div>
                ) : null}
                <div className="login-view">
                    <div style={{width: "50%"}}>
                        <Segment style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Header as="h2" icon>
                                <Icon name='tv'/>
                                {i18n.t(`login.${scuBuild ? 'scu' : 'web'}.productHeader`)}
                                <Header.Subheader>
                                    {i18n.t(`login.${scuBuild ? 'scu' : 'web'}.productSubheader`)}
                                </Header.Subheader>
                            </Header>
                            <List bulleted>
                                <List.Item>{i18n.t(`login.${scuBuild ? 'scu' : 'web'}.productInfo1`)}</List.Item>
                                <List.Item>{i18n.t(`login.${scuBuild ? 'scu' : 'web'}.productInfo2`)}</List.Item>
                                {!scuBuild ? (
                                    <List.Item>{i18n.t(`login.web.productInfo3`)}</List.Item>
                                ) : null}
                            </List>
                        </Segment>
                        {this.state.code ? (
                            <Segment style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Header as="h2" icon>
                                    {i18n.t("login.scu.codeText")}
                                </Header>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <div className={"login-number"}>
                                        {this.state.code[0]}
                                    </div>
                                    <div className={"login-number"}>
                                        {this.state.code[1]}
                                    </div>
                                    <div className={"login-number"}>
                                        {this.state.code[2]}
                                    </div>
                                    <div className={"login-number"}>
                                        {this.state.code[3]}
                                    </div>
                                </div>
                            </Segment>
                        ) : (
                            <div>
                                <Button positive
                                        fluid
                                        content={i18n.t("login.buttonText")}
                                        href={BryxApi.managementSiteUrl}/>
                                {alertMessage}
                            </div>
                        )}
                    </div>
                </div>

                {scuBuild ? (
                    <ContactSupportModal open={this.state.overlayContent == "contactSupport"}
                                         onClose={() => this.setState({
                                             overlayContent: "none",
                                         })}/>
                ) : null}

            </div>
        );
    }
}
