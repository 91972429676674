import * as React from "react";

export interface CarouselItem {
    id: string;
    active: boolean;
}

export interface CarouselProps {
    items: CarouselItem[];
}

export const Carousel: React.SFC<CarouselProps> = props => {
    return (
        <div className="dot-container">
            {props.items.map(a => (
                <span key={a.id}
                      className={a.active ? "dot selected" : "dot"}/>
            ))}
        </div>
    );
};
