import {ParseUtils} from "@bryxinc/lunch";
import {config} from "../config";
import {BryxApi} from "./bryxApi";
import {parseWebsocketMessage} from "./bryxWebSocket";

export class CodeWebSocket {

    private websocketConnection: WebSocket | null = null;
    private onCode: (code: String) => void;
    private onConfig: (id: String) => void;

    constructor(onCode: (code: String) => void, onConfig: (id: String) => void) {
        this.onCode = onCode;
        this.onConfig = onConfig;
        this.open();
    }

    open() {
        this.websocketConnection = new WebSocket(`${BryxApi.wsUrl}/code`);
        this.websocketConnection.onopen = this.onOpen.bind(this);
        this.websocketConnection.onclose = this.onClose.bind(this);
        this.websocketConnection.onmessage = this.onMessage.bind(this);
        this.websocketConnection.onerror = this.onError.bind(this);
    }

    onOpen(_: Event) {
        config.info("CodeWebSocket connected");
    }

    onClose(event: CloseEvent) {
        config.info(`CodeWebSocket onClose (${event.code})`);
        this.close();
        this.open();
    }

    onMessage(event: MessageEvent) {
        const websocketPayload = JSON.parse(event.data);
        const websocketMessage = parseWebsocketMessage(websocketPayload);
        if (websocketMessage.success == true) {

            switch (websocketMessage.value.key) {
                case "subscribeResponse": {
                    const code = ParseUtils.getString(websocketMessage.value.initialData, "code");
                    this.onCode(code);
                    break;
                }
                case "serverUpdate": {
                    const configId = ParseUtils.getString(websocketMessage.value.data, "configId");
                    this.onConfig(configId);
                    break;
                }
            }

        } else {
            config.error(`Failed to parse CodeWebSocket message: ${websocketMessage.justification}`);
        }
    }

    onError(event: Event) {
        config.info(`CodeWebSocket onError (${event})`);
    }

    close() {
        const websocketConnection = this.websocketConnection;
        if (websocketConnection != null) {
            websocketConnection.close();
            this.websocketConnection = null;
        }
    }

}
