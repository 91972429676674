import * as React from "react";
import {Card} from "semantic-ui-react";
import {WeatherOverlayData, WeatherType, WindDirection} from "../../../models/idleOverlays";

interface WeatherInfoOverlayProps {
    weatherData: WeatherOverlayData;
}

type WeatherDisplayType = "currentAndForecast" | "currentOnly" | "forecastOnly";

export class WeatherInfoOverlay extends React.Component<WeatherInfoOverlayProps, {}> {

    static DAYS_OF_WEEK = [
        "Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat",
    ];

    render() {
        const {weatherData} = this.props;

        let displayType: WeatherDisplayType = "currentOnly";
        if (weatherData.forecast != null && weatherData.current != null) {
            displayType = "currentAndForecast";
        } else if (weatherData.current == null && weatherData.forecast != null) {
            displayType = "forecastOnly";
        }

        if (displayType == "currentOnly") {
            const currentData = weatherData.current!!;
            return (
                <Card style={{ width: "50%", marginLeft: "auto", marginRight: "33%" }}>
                    <Card.Content style={{display: "inline-flex", flexDirection: "row"}}>
                        <img src={`/resources/assets/weather_icons/${WeatherType[currentData.type]}.svg`}
                             style={{marginLeft: "4px"}}
                             height={"100px"}
                             width={"100px"}
                             alt={currentData.type.toString()} />
                        <h2 style={{marginLeft: "32px", marginTop: "32px"}}>{currentData.tempToF().toFixed() + '°F'}</h2>
                        <h3 style={{marginLeft: "16px", marginTop: "24px"}}>
                            {`${currentData.humidity}% RH`}
                            <br />
                            {`${currentData.windSpeedMPH().toFixed()} MPH ${WindDirection[currentData.windDirection].toUpperCase()}`}
                        </h3>
                    </Card.Content>
                </Card>
            );
        } else if (displayType == "forecastOnly") {
            const forecast = weatherData.forecast!!;
            const today = new Date();
            return (
                <Card style={{ margin: "0 4em", width: "min-content" }}>
                    <Card.Content style={{display: "inline-flex", flexDirection: "row", maxHeight: "140px"}}>
                        {forecast.map((data, i) => {
                            return (
                                <div key={`weather-${i}`} style={{display: "inline-flex", flexDirection: "column", margin: "5px 20px"}}>
                                    <h3 style={{margin: "0 auto", top: "2px", textAlign: "center"}}>{i == 0 ? "Today" : WeatherInfoOverlay.DAYS_OF_WEEK[(today.getDay() + i) % 7]}</h3>
                                    <img src={`/resources/assets/weather_icons/${WeatherType[data.type]}.svg`}
                                         height={"60px"}
                                         width={"60px"}
                                         alt={data.type.toString()} />
                                    <h3 style={{margin: "0 auto", bottom: "2px"}}>{data.tempToF().toFixed() + '°F'}</h3>
                                </div>
                            );
                        })}
                    </Card.Content>
                </Card>
            );
        } else { /* currentAndForecast */
            const currentData = weatherData.current!!;
            const forecast = weatherData.forecast!!;
            const today = new Date();
            return (
                <Card style={{ margin: "0 4em", width: "min-content" }}>
                    <Card.Content style={{display: "inline-flex", flexDirection: "row"}}>
                        <div style={{display: "inline-flex", flexDirection: "column", margin: "5px 20px"}}>
                            <h3 style={{margin: "0 auto", top: "2px", textAlign: "center"}}>Today</h3>
                            <img src={`/resources/assets/weather_icons/${WeatherType[currentData.type]}.svg`}
                                 style={{marginLeft: "4px"}}
                                 height={"70px"}
                                 width={"70px"}
                                 alt={currentData.type.toString()} />
                            <span style={{margin: "0 auto", bottom: "2px", textAlign: "center"}}>
                                <h3 style={{margin: 0}}>{currentData.tempToF().toFixed() + '°F'}</h3>
                                <h4 style={{margin: 0}}>
                                    {`${currentData.humidity}% RH`}
                                    <br/>
                                    {`${currentData.windSpeedMPH().toFixed()} MPH ${WindDirection[currentData.windDirection].toUpperCase()}`}
                                </h4>
                            </span>
                        </div>
                        <span style={{height: "100%", border: "1px solid #33333322"}} />
                        {forecast.map((data, i) => {
                            if (i == 0) {
                                return;
                            }
                            return (
                                <div key={`weather-${i}`} style={{display: "inline-flex", flexDirection: "column", margin: "auto 20px"}}>
                                    <h3 style={{margin: "0.5em auto", textAlign: "center"}}>{WeatherInfoOverlay.DAYS_OF_WEEK[(today.getDay() + i) % 7]}</h3>
                                    <img src={`/resources/assets/weather_icons/${WeatherType[data.type]}.svg`}
                                         height={"60px"}
                                         width={"60px"}
                                         alt={data.type.toString()} />
                                    <h3 style={{margin: "0.5em auto"}}>{data.tempToF().toFixed() + '°F'}</h3>
                                </div>
                            );
                        })}
                    </Card.Content>
                </Card>
            );
        }
    }
}
