import * as moment from "moment";
import * as React from "react";
import {Segment} from "semantic-ui-react";
import {ListJob} from "../../models/job";
import {BryxColors} from "../../utils/bryxColors";
import {ProgressAttachment} from "./progressAttachment";

interface JobOverlayTurnoutProps {
    job: ListJob;
    durationRemaining: number;
    expirationTime: Date;
    turnoutTime: number;
    blinkTime: number | null;
    redTime: number | null;
    now: Date;
}

export class JobOverlayTurnout extends React.Component<JobOverlayTurnoutProps, {}> {
    render() {
        const {durationRemaining, blinkTime, redTime} = this.props;

        if (durationRemaining < 0) {
            return null;
        }

        return (
            <Segment style={{marginTop: 0}}>
                <div
                    className={blinkTime && durationRemaining <= blinkTime ? 'turnout-blink' : undefined}
                    style={{
                        textAlign: "center",
                        fontSize: "50px",
                        lineHeight: "35px",
                        fontWeight: 600,
                        color: redTime != null && durationRemaining <= redTime ? BryxColors.darkRed : "#4e4e4e",
                    }}>
                    {moment.utc(durationRemaining * 1000).format('HH:mm:ss')}
                </div>
                <ProgressAttachment expirationTime={this.props.expirationTime} redTime={this.props.redTime} turnoutTime={this.props.turnoutTime} now={this.props.now} />
            </Segment>
        );
    }
}
