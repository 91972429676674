import {ParseResult, ParseUtils} from "@bryxinc/lunch";

export class MinimalUnit {
    public constructor(
        public id: string,
        public name: string,
    ) {}

    static parse(o: any): ParseResult<MinimalUnit> {
        try {
            return ParseUtils.parseSuccess(new MinimalUnit(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MinimalUnit>(`Invalid MinimalUnit Model: ${e.message}`);
        }
    }
}
