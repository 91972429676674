import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {Point} from "geojson";

export class Hydrant {
    private constructor(
        public mainSize: number | null,
        public color: string,
        public inService: boolean,
        public location: Point,
    ) {}

    static parse(o: any): ParseResult<Hydrant> {
        try {
            return ParseUtils.parseSuccess(new Hydrant(
                ParseUtils.getNumberOrNull(o, "mainSize"),
                ParseUtils.getString(o, "color"),
                ParseUtils.getBoolean(o, "inService"),
                o["location"],
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Hydrant>(`Invalid Hydrant Model: ${e.message}`);
        }
    }
}
