import * as i18n from "i18next";
import * as XHR from "i18next-xhr-backend";
import * as QueryParams from 'query-string';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import {Redirect, RouteComponentProps} from "react-router";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Header, Icon, Loader, Menu, Popup, Segment} from "semantic-ui-react";
import Clock from "./components/clock";
import {ContactSupportModal} from "./components/contactSupportModal";
import {MultipleConnectionsModal} from "./components/multipleConnectionsModal";
import {config} from "./config";
import {Login} from "./login";
import {SessionData} from "./models/boardUpdate";
import {StationBoard} from './pages/webBoard';
import {BryxApi} from "./utils/bryxApi";
import {BryxColors} from "./utils/bryxColors";
import {BryxLocal} from "./utils/bryxLocal";
import {BryxWebSocket, BryxWebSocketState, BryxWebSocketStateObserver} from "./utils/bryxWebSocket";
import {JobManager} from "./utils/jobManager";
import {SessionManager, SessionManagerObserver} from "./utils/sessionManager";
import {UpdateManager, UpdateManagerObserver, UpdateStatus} from "./utils/updateManager";
import Options = i18n.Options;

type SignOutType = "standard" | "manual" | "forced" | "passwordChanged";
type OverlayContentKey = "none" | "menu" | "duty" | "notifications" | "settings" | "contactSupport" | "permissions" | "releaseNotes" | "about" | "apparatusSignOut";

interface MainState {
    isLoading: boolean;
    status: {key: "signedIn"} |
        {key: "signedOut", type: SignOutType};
    overlayContent: OverlayContentKey;
    webSocketState: BryxWebSocketState;
    sessionData: SessionData | null;
}

export class Main extends React.Component<RouteComponentProps<any>, MainState> implements BryxWebSocketStateObserver, UpdateManagerObserver, SessionManagerObserver {

    constructor(props: any, context: any) {
        super(props, context);

        BryxApi.onUnauthenticated = () => {
            config.info("User is being force signed out");
            Main.resetManagers();
            this.setState({
                isLoading: false,
                status: {key: "signedOut", type: "forced"},
            });
        };

        i18n
            .use(XHR)
            .init({
                lng: BryxLocal.getItem<string>("locale") || "en-US",
                backend: {
                    loadPath: "/resources/locales/{{lng}}.json",
                },
                fallbackLng: "en",
            } as Options, () => {
                this.loadSession();
            });

        this.state = {
            isLoading: BryxLocal.isSignedIn(),
            status: BryxLocal.isSignedIn() ? {key: "signedIn"} : {key: "signedOut", type: "standard"},
            overlayContent: "none",
            webSocketState: BryxWebSocket.shared.state,
            sessionData: null,
        };
    }

    componentDidMount() {
        BryxWebSocket.shared.registerStateObserver(this);
        UpdateManager.shared.registerObserver(this);
        UpdateManager.shared.start();
        SessionManager.shared.registerObserver(this);
        const params = QueryParams.parse(this.props.location.search);
        if (params != null) {
            if (params['invert'] == "true") {
                BryxLocal.setItem("invertClientPins", true);
            } else {
                BryxLocal.setItem("invertClientPins", false);
            }

            if (params['sound'] == "true") {
                BryxLocal.setItem("playSound", true);
            } else {
                BryxLocal.setItem("playSound", false);
            }
        }
    }

    componentWillUnmount() {
        BryxWebSocket.shared.unregisterStateObserver(this);
        UpdateManager.shared.unregisterObserver(this);
        SessionManager.shared.unregisterObserver(this);
    }

    private loadSession() {
        if (BryxLocal.isSignedIn()) {
            this.setState({
                isLoading: false,
                status: {key: "signedIn"},
            });
            SessionManager.shared.start();
            JobManager.shared.startLoadingJobs();
        }
    }

    public static resetManagers() {
        JobManager.shared.reset(false);
        UpdateManager.shared.reset();
        SessionManager.shared.reset();
    }

    // BryxWebSocketStateObserver Functions

    websocketStateDidChange(state: BryxWebSocketState) {
        this.setState({webSocketState: state});
    }

    // UpdateManagerObserver Functions

    updateManagerDidChangeUpdateStatus(updateStatus: UpdateStatus) {
        if (updateStatus.key == "updateAvailable") {
            console.log("Refreshing to fetch available update");
            UpdateManager.shared.update();
        }
    }

    // SessionManagerObserver Functions
    sessionManagerDidUpdateData(sessionData: SessionData) {
        this.setState({sessionData: sessionData});
    }

    public sessionManagerDidDestroySession(forced: boolean): void {
        Main.resetManagers();
        this.setState({
            status: {
                key: "signedOut",
                type: forced ? "forced" : "manual",
            },
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{backgroundColor: BryxColors.brandRed, height: "100%", width: "100%"}}>
                    <img id="loadingIcon" src="/resources/assets/logo_white.png" className="centerVertically" alt="Loading..." />
                </div>
            );
        }

        const scuBuild = config.scuBuild;
        const dev = config.serverType == "dev";

        if (scuBuild && (window as any).electron == undefined && !dev) {
            return (
                <div id="mainRoot">
                    <Menu attached="top" color="red" inverted style={{backgroundColor: BryxColors.brandRed, borderRadius: 0}}>
                        <Menu.Item>
                            <img src={"/resources/assets/logo_white.png"} style={{width: "26px"}} alt="Bryx"/> <span className="siteName">{i18n.t(`branding.${config.scuBuild ? 'scu' : 'web'}.siteName`)}</span>
                        </Menu.Item>
                    </Menu>
                    <div className="login-view">
                        <div style={{width: "50%"}}>
                            <Segment style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Header as="h2" icon>
                                    <Icon name="warning"/>
                                    {i18n.t(`login.scu.unsupportedHeader`)}
                                    <Header.Subheader>
                                        {i18n.t(`login.scu.unsupportedSubheader`)}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.status.key == "signedOut") {
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {
                        type: this.state.status.type,
                        from: this.state.status.type != "manual" ? this.props.location : null,
                    },
                }}/>
            );
        }

        return (
            <div id="mainRoot">
                <Menu attached="top" color="red" inverted style={{backgroundColor: BryxColors.brandRed, borderRadius: 0}}>
                    <Menu.Item position={'left'} style={{borderWidth: '0px', boxShadow: 'none'}}>
                        <img src={"/resources/assets/logo_white.png"} style={{width: "26px"}} alt="Bryx"/> <span className="siteName">{i18n.t(`branding.${config.scuBuild ? 'scu' : 'web'}.siteName`)}</span>
                    </Menu.Item>
                    <Menu.Item style={{flex: 1, textAlign: 'center'}}>
                        <Clock size={20} hour24 />
                    </Menu.Item>
                    <Menu.Menu position="right" style={{borderWidth: '0px', boxShadow: 'none'}}>
                        {this.state.webSocketState == BryxWebSocketState.reconnecting ? (
                            <Menu.Item>
                                <Popup trigger={
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Loader active
                                                inline
                                                inverted
                                                size="small"
                                                style={{marginRight: "10px"}}/>
                                        {i18n.t("general.reconnecting")}
                                    </div>
                                }>
                                    {i18n.t("general.reconnectingToBryx")}
                                </Popup>
                            </Menu.Item>
                        ) : null}
                        {this.state.sessionData != null ? (<Menu.Item>{this.state.sessionData.sessionName}</Menu.Item>) : null}
                        {!scuBuild ? (
                            <Popup on="click"
                                   position="bottom right"
                                   open={this.state.overlayContent == "menu"}
                                   onOpen={() => this.setState({overlayContent: "menu"})}
                                   onClose={() => this.setState({overlayContent: "none"})}
                                   style={{right: "11px"}}
                                   trigger={
                                       <Menu.Item>
                                           <Icon name='setting' size="big" style={{margin: 0}}/>
                                       </Menu.Item>
                                   }>
                                <div className="popupItem" onClick={() => this.setState({overlayContent: "contactSupport"})}>{i18n.t("contactSupport.header")}</div>
                                <Popup.Content>
                                    <div className="popupItem" onClick={() => SessionManager.shared.signOut()}>{i18n.t("nav.settings.signOut")}</div>
                                </Popup.Content>
                            </Popup>
                        ) : null}
                    </Menu.Menu>
                </Menu>

                <div id="contentWrapper">
                    <Switch>
                        <Route path="/" component={StationBoard} />
                    </Switch>
                </div>
                {!scuBuild ? <ContactSupportModal open={this.state.overlayContent == "contactSupport"}
                                                 onClose={() => this.setState({overlayContent: "none"})}/> : null}
                <MultipleConnectionsModal open={this.state.webSocketState == BryxWebSocketState.closed}/>
            </div>
        );
    }
}

export class MainRouter extends React.Component<any, any> {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route component={Main}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

window.onerror = (message: string, filename?: string, lineno?: number, colno?: number, error?: Error) => {
    config.error(message, filename, lineno, colno, error);
};

ReactDom.render(
    <MainRouter/>,
    document.getElementById("content"),
);
