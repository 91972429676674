import {ReactElement} from "react";
import * as React from "react";
import {UnitColorMap} from "../../models/board";
import {ListJob} from "../../models/job";

interface JobOverlayUnitColorProps {
    job: ListJob;
    map: UnitColorMap;
}

export class JobOverlayUnitColor extends React.Component<JobOverlayUnitColorProps, {}> {
    render() {
        const {job, map} = this.props;

        const matchedUnits = job.assignments.filter(a => Object.keys(map).indexOf(a.id) != -1);
        const numberOfBlocks = matchedUnits.length;

        if (numberOfBlocks < 1) {
            return null;
        }

        const colorBlocks: Array<ReactElement<any>> = [];
        const maxWidth = 100 / numberOfBlocks;

        for (let i = 1; i <= numberOfBlocks; i++) {
            const start = 100 - (maxWidth * i);
            colorBlocks.push(
                <div key={`color-${i}`} style={{
                    display: "inline-flex",
                    backgroundColor: map[matchedUnits[i - 1].id],
                    width: `${maxWidth}vw`,
                    left: `${start}vw`,
                    height: "100%",
                }} />,
            );
        }

        return (
            <div style={{
                zIndex: 9999999999999,
                position: "fixed",
                display: "block",
                flex: numberOfBlocks,
                flexDirection: "row",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: "100vw",
                maxWidth: "100vw",
                height: "4em",
            }}>
                {colorBlocks.map(x => x)}
            </div>
        );
    }
}
