import * as i18n from "i18next";
import * as React from "react";
import {Label, Segment} from "semantic-ui-react";
import {SupplementalInfo} from "../../models/job";
import {DateUtils} from "../../utils/dateUtils";
import {KeyValueView} from "../keyValueView";

interface JobOverlaySupplementalsProps {
    supplementals: SupplementalInfo[];
    style?: React.CSSProperties;
}

export class JobOverlaySupplementals extends React.Component<JobOverlaySupplementalsProps, {}> {

    render() {
        const {supplementals} = this.props;

        return (
            <Segment className={`labeled supplemental`} style={{overflowY: "hidden", ...this.props.style}}>
                <Label attached="top">{i18n.t("jobs.jobInfo.supplementals.title")}</Label>
                <div style={{fontSize: "18px"}}>
                    {supplementals.sort(SupplementalInfo.compare).filter((v, i, a) => {
                        return (i === 0) || (v.id !== a[i - 1].id);
                    }).map((s, i) => (
                        <KeyValueView key={`${s.id}-${i}`} keyNode={DateUtils.formatTime(s.ts)} keyWidth="120px"
                                      valueNode={s.text}/>
                    ))}
                </div>
            </Segment>
        );
    }

}
