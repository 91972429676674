import {ParseResult, ParseUtils} from "@bryxinc/lunch";

export class InfoReturn {
    constructor(
        public key: string,
        public value: string,
    ) {}

    public static parse(o: any): ParseResult<InfoReturn> {
        try {
            return ParseUtils.parseSuccess(new InfoReturn(
                ParseUtils.getString(o, "key"),
                ParseUtils.getString(o, "value"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<InfoReturn>(`Invalid InfoReturn model: ${e.message}`);
    }
}}

export class SiteSurvey {
    constructor(
        public critical: string | null,
        public advisory: string[],
        public informational: InfoReturn[],
    ) {}

    public static parse(o: any): ParseResult<SiteSurvey> {
        try {
            return ParseUtils.parseSuccess(new SiteSurvey(
                ParseUtils.getStringOrNull(o, "critical"),
                ParseUtils.getArray(o, "advisory"),
                ParseUtils.getArrayOfSubobjects(o, "informational", InfoReturn.parse, "warn"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<SiteSurvey>(`Invalid SiteSurvey model: ${e.message}`);
    }
}}
