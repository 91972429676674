import * as moment from "moment";
import * as React from "react";
import {StationBoardTurnoutStartCondition, UnitColorMap} from "../../models/board";
import {ListJob} from "../../models/job";
import {SiteSurvey} from "../../models/siteSurvey";
import {ApiResult, BryxApi} from "../../utils/bryxApi";
import {Carousel} from "../carousel";
import {JobOverlayDetail} from "./jobOverlayDetail";
import {JobOverlayDrone} from "./jobOverlayDrone";
import {JobOverlayResponders} from "./jobOverlayResponders";
import {JobOverlayCriticalWarning} from "./jobOverlaySiteSurvey";
import {JobOverlayStreetView} from "./jobOverlayStreetView";
import {JobOverlaySupplementals} from "./jobOverlaySupplementals";
import {JobOverlayTurnout} from "./jobOverlayTurnout";
import {JobOverlayUnitColor} from "./jobOverlayUnitColor";

interface ActiveJob {
    job: ListJob;
    displayed: boolean;
}

interface ReceivedJobCache {
    timeReceived: Date;
    criticalWarning: string | null;
}

interface JobOverlayProps {
    activeJobs: ActiveJob[];
    now: Date;
    showTurnout: boolean;
    showStreetView: boolean;
    showResponders: boolean;
    showSupplementals: boolean;
    showCriticalWarning: boolean;
    showDrone: boolean;

    activeTime: number;
    turnoutTime: number;
    redTime: number | null;
    blinkTime: number | null;
    turnoutStart: StationBoardTurnoutStartCondition;
    unitColorMap: UnitColorMap | null;
}

interface JobOverlayState {
    jobsReceived: {
        [key: string]: ReceivedJobCache;
    };
}

export class JobOverlay extends React.Component<JobOverlayProps, JobOverlayState> {
    constructor(props: JobOverlayProps, context: any) {
        super(props, context);
        this.state = {
            jobsReceived: {},
        };
    }

    isJobInCache(id: string): boolean {
        return Object.keys(this.state.jobsReceived).indexOf(id) > -1;
    }

    componentDidUpdate(nextProps: Readonly<JobOverlayProps>, nextContext: any): void {
        const {jobsReceived} = this.state;
        const newDisplayedJob = nextProps.activeJobs.filter(a => a.displayed)[0];

        if (newDisplayedJob == null) {
            return;
        }

        if (!this.isJobInCache(newDisplayedJob.job.id)) {
            BryxApi.getSiteSurveys(newDisplayedJob.job.id, (result: ApiResult<SiteSurvey>) => {
                jobsReceived[newDisplayedJob.job.id] = {
                    timeReceived: new Date(),
                    criticalWarning: result.success ? result.value.critical : null,
                };
                this.setState({
                    jobsReceived: jobsReceived,
                });
            });
        }
    }

    getMomentReceivedFor(id: string): moment.Moment {
        const {jobsReceived} = this.state;

        const candidates = Object.keys(this.state.jobsReceived).filter(theId => theId == id);
        if (candidates.length == 0) {
            return moment();
        } else {
            return moment(jobsReceived[candidates[0]].timeReceived);
        }
    }

    getDisplayedJob(): ActiveJob | null {
        return this.props.activeJobs.filter(a => a.displayed)[0];
    }

    render() {
        const displayedJob = this.getDisplayedJob();
        if (displayedJob == null) {
            return null;
        }

        const { jobsReceived } = this.state;

        const nowMoment = moment(this.props.now);

        const jobReceivedMoment = this.props.turnoutStart == StationBoardTurnoutStartCondition.receivedTime ? this.getMomentReceivedFor(displayedJob.job.id)
                                    : this.props.turnoutStart == StationBoardTurnoutStartCondition.jobCreation ? moment(displayedJob.job.creationTime)
                                        : moment(displayedJob.job.ts);

        const turnoutExpirationMoment = moment(jobReceivedMoment.clone().add(this.props.turnoutTime, 'seconds'));
        const turnoutDurationRemaining = moment.duration(turnoutExpirationMoment.diff(nowMoment));
        const expirationMoment = moment(displayedJob.job.creationTime.getTime() + (this.props.activeTime * 1000));

        const cachedSiteSurvey = this.isJobInCache(displayedJob.job.id) ? jobsReceived[displayedJob.job.id].criticalWarning : null;

        return (
            <div className="carousel-container">
                <div style={{flex: 1, marginRight: "10px", height: "100%", display: "flex", flexDirection: "column"}}>
                    {this.props.showCriticalWarning && cachedSiteSurvey ? (
                        <JobOverlayCriticalWarning criticalWarning={cachedSiteSurvey} />
                    ) : null}
                    <JobOverlayDetail job={displayedJob.job} expirationTime={expirationMoment.toDate()} activeTime={this.props.activeTime} redTime={this.props.redTime} now={this.props.now} attachProgress={!this.props.showTurnout || this.props.turnoutTime != this.props.activeTime}/>
                    {this.props.showTurnout ? (
                        <JobOverlayTurnout job={displayedJob.job} durationRemaining={turnoutDurationRemaining.asSeconds()} expirationTime={turnoutExpirationMoment.toDate()} turnoutTime={this.props.turnoutTime} blinkTime={this.props.blinkTime} redTime={this.props.redTime} now={this.props.now}/>
                    ) : null}
                    {this.props.showStreetView ? (
                        <JobOverlayStreetView job={displayedJob.job}/>
                    ) : null}
                    {this.props.showSupplementals && displayedJob.job.supplementals.length > 0 ? (
                        <JobOverlaySupplementals
                            supplementals={displayedJob.job.supplementals}
                            style={{flexShrink: 0}}/>
                    ) : null}
                    {this.props.showResponders && displayedJob.job.responders.length > 0 ? (
                        <JobOverlayResponders
                            responders={displayedJob.job.responders}
                            style={{flexShrink: 1}}/>
                    ) : null}
                    {this.props.showDrone ? (
                        <JobOverlayDrone job={displayedJob.job} />
                    ) : null}
                </div>
                {this.props.activeJobs.length > 1 ? (
                    <Carousel items={this.props.activeJobs.map(a => ({id: a.job.id, active: a.displayed}))}/>
                ) : null}
                {this.props.unitColorMap ? (
                    <JobOverlayUnitColor job={displayedJob.job} map={this.props.unitColorMap} />
                ) : null}
            </div>
        );
    }
}
