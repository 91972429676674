import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {StationBoardInactiveLayer} from "./board";

// Weather
export type WeatherForecastType = StationBoardInactiveLayer.forecast5 | null; // | FutureLayerEnum1 | FutureLayerEnum2

export enum WeatherType {
    clear, ice, mostlyCloudy, partlyCloudy, rain,
    snow, snowScattered, thunderstorms, thunderstormsScattered,
    windy,
}

export enum WindDirection {
    n, ne, e, se, s,
    sw, w, nw,
}

export class WeatherForecast {
    constructor(
        public temperature: number,
        public type: WeatherType,
        public ts: Date,
    ) {}

    public tempToF(): number {
        return (this.temperature * 1.8) + 32;
    }

    public static parse(o: any): ParseResult<WeatherForecast> {
        try {
            return ParseUtils.parseSuccess(new WeatherForecast(
                ParseUtils.getNumber(o, "temperature"),
                ParseUtils.getEnum(o, "type", WeatherType),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<WeatherForecast>(`Invalid WeatherData Model: ${e.message}`);
        }
    }
}

export class CurrentWeatherForecast extends WeatherForecast {
    constructor(
        temperature: number,
        type: WeatherType,
        ts: Date,
        public windSpeed: number,
        public windDirection: WindDirection,
        public humidity: number,
    ) {
        super(temperature, type, ts);
    }

    public windSpeedMPH(): number {
        return this.windSpeed / 1.609344;
    }

    public static parse(o: any): ParseResult<CurrentWeatherForecast> {
        try {
            return ParseUtils.parseSuccess(new CurrentWeatherForecast(
                ParseUtils.getNumber(o, "temperature"),
                ParseUtils.getEnum(o, "type", WeatherType),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getNumber(o, "windSpeed"),
                ParseUtils.getEnum(o, "windDirection", WindDirection),
                ParseUtils.getNumber(o, "humidity"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<CurrentWeatherForecast>(`Invalid CurrentWeatherData Model: ${e.message}`);
        }
    }
}

export type WeatherOverlayData = {current: CurrentWeatherForecast | null; forecast: WeatherForecast[] | null};

export type WeatherUpdateInitialData = {
    type: "initialData",
    current: CurrentWeatherForecast | null,
    forecast: WeatherForecast[] | null,
};
export type WeatherUpdateCurrent = {
    type: "current",
    current: CurrentWeatherForecast,
};
export type WeatherUpdateForecast = {
    type: "forecast",
    forecast: WeatherForecast[],
};
export type WeatherUpdate = WeatherUpdateInitialData | WeatherUpdateCurrent | WeatherUpdateForecast;

export function parseIdleOverlayUpdate(message: BryxWebSocketMessage): ApiResult<WeatherUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");

                switch (type) {
                    case "current":
                        return apiSuccess<WeatherUpdateCurrent>({
                            type: "current",
                            current: ParseUtils.getSubobject(message.data, "current", CurrentWeatherForecast.parse),
                        });
                    case "forecast":
                        return apiSuccess<WeatherUpdateForecast>({
                            type: "forecast",
                            forecast: ParseUtils.getArrayOfSubobjects(message.data, "forecast", WeatherForecast.parse, "throw"),
                        });
                    default:
                        return apiFailure(null, `Invalid MapUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }

                return apiSuccess<WeatherUpdateInitialData>({
                    type: "initialData",
                    current: ParseUtils.getSubobjectOrNull(message.initialData, "current", CurrentWeatherForecast.parse),
                    forecast: ParseUtils.getArrayOfSubobjectsOrNull(message.initialData, "forecast", WeatherForecast.parse, "throw"),
                });
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid WeatherUpdate Model: ${e.message}`);
    }
}

// Unit Status
// TODO: this is all made up

export enum UnitStatus {
    inService, outOfService, enRoute, onScene, transporting, partialAvailable,
}

export class UnitStatusData {
    constructor(
        public name: string,
        public status: UnitStatus,
    ) {}

    public static parse(o: any): ParseResult<UnitStatusData> {
        try {
            return ParseUtils.parseSuccess(new UnitStatusData(
                ParseUtils.getString(o, "name"),
                ParseUtils.getEnum(o, "status", UnitStatus),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<UnitStatusData>(`Invalid UnitStatusData model: ${e.message}`);
    }
}}
