import {config} from "../config";
import {StationBoardConfig} from "../models/board";
import {BoardUpdate, BoardUpdateInitialData, SessionData} from "../models/boardUpdate";
import {DroneFlight} from "../models/drone";
import {Message} from "../models/message";
import {ApiResult, BryxApi} from "./bryxApi";
import {BryxLocal} from "./bryxLocal";
import {BryxWebSocket} from "./bryxWebSocket";

export interface SessionManagerObserver {
    sessionManagerDidUpdateData?(sessionData: SessionData): void;
    sessionManagerDidDestroySession?(forced: boolean): void;
}

export class SessionManager {
    private static BOARD_WS_KEY = "SessionManager-board";

    private observers: SessionManagerObserver[] = [];

    static shared = new SessionManager();

    public sessionData: SessionData | null;

    public start() {
        if (this.sessionData != null) {
            config.error("Attempting to start SessionManager with existing session data");
            return;
        }
        BryxApi.subscribeToBoard(SessionManager.BOARD_WS_KEY, this.onBoardUpdate.bind(this));
    }

    private onBoardUpdate(result: ApiResult<BoardUpdate>) {
        if (result.success == true) {
            switch (result.value.type) {
                case "initialData":
                    this.initializeSessionData(result.value);
                    break;
                case "config":
                    this.updateBoard(result.value.board);
                    break;
                case "message":
                    this.updateMessage(result.value.message);
                    break;
                case "signOut":
                    this.sessionDestroyed(true);
                    break;
                case "drone":
                    this.updateDrone(result.value.flight);
                    break;
                case "reconnect":
                    BryxWebSocket.shared.reconnect(`wss://${result.value.cluster}/api/2.2`);
                    setTimeout(() => {
                        if (this.sessionData != null && this.sessionData.videoFeed != null) {
                            BryxApi.subscribeToScu(`${SessionManager.BOARD_WS_KEY}-${this.sessionData.videoFeed.scuId}`, this.sessionData.videoFeed.scuId, this.onBoardUpdate.bind(this));
                        }
                    }, 1000);
                    break;
                case "videoFeedStart":
                case "videoFeedStop":
                    this.updateVideoFeed(result.value.type, result.value.scuId);
                    break;
                case "videoFeedFrame":
                    this.updateVideoFrame(result.value.frame);
            }
        } else {
            config.error(`Session websocket failed: ${result.debugMessage}`);
        }
    }

    private initializeSessionData(initialData: BoardUpdateInitialData) {
        const sessionData = new SessionData(initialData.sessionName, initialData.board, initialData.message, null, initialData.agencyBounds, null);
        this.sessionData = sessionData;
        this.observers.forEach(o => o.sessionManagerDidUpdateData && o.sessionManagerDidUpdateData(sessionData));
    }

    private updateVideoFeed(event: "videoFeedStart" | "videoFeedStop", scuId: string) {
        const sessionData = this.sessionData;
        if (sessionData) {
            if (event == "videoFeedStart") {
                sessionData.videoFeed = {
                    scuId,
                };
                // make sure that we blow this away in case we don't get a videoFeedStop message
                setTimeout(() => {
                    sessionData.videoFeed = null;
                    this.sessionData = sessionData;
                    this.observers.forEach(o => o.sessionManagerDidUpdateData && o.sessionManagerDidUpdateData(sessionData));
                }, 15000);
            } else {
                sessionData.videoFeed = null;
            }
            this.sessionData = sessionData;
            this.observers.forEach(o => o.sessionManagerDidUpdateData && o.sessionManagerDidUpdateData(sessionData));
        } else {
            config.error("Failed to update board, session data is not initialized");
        }
    }

    private updateVideoFrame(frame: string) {
        const sessionData = this.sessionData;
        if (sessionData) {
            if (sessionData.videoFeed == null) {
                sessionData.videoFeed = {
                    scuId: "unknown",
                    currentFrame: frame,
                };
            } else {
                sessionData.videoFeed.currentFrame = frame;
            }
            this.sessionData = sessionData;
            this.observers.forEach((o) => o.sessionManagerDidUpdateData && o.sessionManagerDidUpdateData(sessionData));
        } else {
            config.error("Failed to update board, session data is not initialized");
        }
    }

    private updateBoard(board: StationBoardConfig) {
        const sessionData = this.sessionData;
        if (sessionData) {
            sessionData.board = board;
            this.observers.forEach(o => o.sessionManagerDidUpdateData && o.sessionManagerDidUpdateData(sessionData));
        } else {
            config.error("Failed to update board, session data is not initialized");
        }
    }

    private updateMessage(message: Message | null) {
        const sessionData = this.sessionData;
        if (sessionData) {
            sessionData.message = message;
            this.observers.forEach(o => o.sessionManagerDidUpdateData && o.sessionManagerDidUpdateData(sessionData));
        } else {
            config.error("Failed to update message, session data is not initialized");
        }
    }

    private updateDrone(drone: DroneFlight) {
        const sessionData = this.sessionData;
        if (sessionData) {
            sessionData.drone = drone;
            this.observers.forEach(o => o.sessionManagerDidUpdateData && o.sessionManagerDidUpdateData(sessionData));
        } else {
            config.error("Failed to update drone, session data is not initialized");
        }
    }

    public signOut() {
        BryxApi.unsubscribe(SessionManager.BOARD_WS_KEY);
        BryxApi.signOut();
        this.sessionData = null;
        BryxLocal.clear();
        this.observers.forEach(o => o.sessionManagerDidDestroySession && o.sessionManagerDidDestroySession(false));
    }

    private sessionDestroyed(notify: boolean) {
        BryxApi.unsubscribe(SessionManager.BOARD_WS_KEY);
        this.sessionData = null;
        BryxLocal.clear();
        if (notify) {
            this.observers.forEach(o => o.sessionManagerDidDestroySession && o.sessionManagerDidDestroySession(true));
        }
    }

    public reset() {
        this.sessionDestroyed(false);
    }

    // SessionManagerObservers

    public registerObserver(observer: SessionManagerObserver) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: SessionManagerObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
