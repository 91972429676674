import * as i18n from "i18next";
import * as React from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {UpdateManager} from "../utils/updateManager";

interface MultipleConnectionsModalProps {
    open: boolean;
}

export class MultipleConnectionsModal extends React.Component<MultipleConnectionsModalProps, {}> {

    render() {
        return (
            <Modal open={this.props.open}
                   size="small">
                <Modal.Header>{i18n.t("multipleConnectionsModal.title")}</Modal.Header>
                <Modal.Content>{i18n.t("multipleConnectionsModal.body")}</Modal.Content>
                <Modal.Actions>
                    <Button primary
                            content={i18n.t("general.reload")}
                            onClick={() => UpdateManager.shared.update()}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
