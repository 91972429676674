import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {GeometryObject, LineString, Point} from "geojson";
import {config} from "../config";
import {BryxApi} from "../utils/bryxApi";
import {arraysEqual} from "../utils/functions";
import {Address} from "./address";
import {Hydrant} from "./hydrant";
import {JobTypeInformation} from "./jobTypeInformation";
import {Responder} from "./responder";
import {StreetViewResult} from "./streetViewResult";

export enum Disposition {
    open, old, closed,
}

export class Assignment {
    public constructor(
        public id: string,
        public ts: Date,
    ) {}

    static parse(o: any): ParseResult<Assignment> {
        try {
            return ParseUtils.parseSuccess(new Assignment(
                ParseUtils.getString(o, "id"),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Assignment>(`Invalid Assignment Model: ${e.message}`);
        }
    }
}

export class SupplementalInfo {
    public constructor(
        public id: string,
        public ts: Date,
        public text: string,
    ) {}

    static parse(o: any): ParseResult<SupplementalInfo> {
        try {
            return ParseUtils.parseSuccess(new SupplementalInfo(
                ParseUtils.getString(o, "id"),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getString(o, "text"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<SupplementalInfo>(`Invalid SupplementalInfo Model: ${e.message}`);
        }
    }

    static compare(s1: SupplementalInfo, s2: SupplementalInfo): number {
        return s2.ts.getTime() - s1.ts.getTime() || s1.id.localeCompare(s2.id);
    }
}

export class RouteLine {
    public constructor(
        public coordinates: number[][],
        public distance: number | null,
        public duration: number | null,
    ) {}

    public lineString: LineString = {
        type: "LineString",
        coordinates: this.coordinates,
    };

    static parse(o: any): ParseResult<RouteLine> {
        try {
            return ParseUtils.parseSuccess(new RouteLine(
                ParseUtils.getArray(o, "coordinates"),
                o.properties ? ParseUtils.getNumberOrNull(o.properties, "distance") : null,
                o.properties ? ParseUtils.getNumberOrNull(o.properties, "duration") : null,
            ));
        } catch (e) {
            return ParseUtils.parseFailure<RouteLine>(`Invalid RouteLine Model: ${e.message}`);
        }
    }
}

export class MinimalJob {
    public constructor(
        public id: string,
        public creationTime: Date,
        public ts: Date,
        public typeInformation: JobTypeInformation,
        public unitShortNames: string[],
        public synopsis: string,
        public centroid: Point | null,
        public address: Address | null,
        public disposition: Disposition,
    ) {}

    private streetViewResult: StreetViewResult | null = null;

    public getStreetViewResult(callback: (result: StreetViewResult) => void) {
        if (this.streetViewResult != null) {
            callback(this.streetViewResult);
            return;
        } else if (this.centroid == null) {
            callback({key: "none"});
            return;
        }
        BryxApi.getStreetView(this.id, result => {
            if (result.key != "error") {
                this.streetViewResult = result;
            }
            callback(result);
        });
    }

    static parse(o: any): ParseResult<MinimalJob> {
        try {
            return ParseUtils.parseSuccess(new MinimalJob(
                ParseUtils.getString(o, "id"),
                ParseUtils.getUNIXTimestampDateOrNull(o, "creationTs") || ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getSubobject(o, "type", JobTypeInformation.parse),
                ParseUtils.getArray(o, "unitShortNames"),
                ParseUtils.getString(o, "synopsis"),
                o["centroid"] || null,
                ParseUtils.getSubobject(o, "address", Address.parse),
                ParseUtils.getEnum(o, "disposition", Disposition),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MinimalJob>(`Invalid Minimal Job Model: ${e.message}`);
        }
    }

    get shortDepartment(): string { return this.unitShortNames.join(', '); }
    get isOpen(): boolean { return this.disposition != Disposition.closed; }
}

export class ListJob extends MinimalJob {
    private constructor(
        public id: string,
        public creationTime: Date,
        public ts: Date,
        public typeInformation: JobTypeInformation,
        public unitShortNames: string[],
        public synopsis: string,
        public centroid: Point | null,
        public crossStreets: string | null,
        public address: Address | null,
        public disposition: Disposition,

        public assignments: Assignment[],
        public route: RouteLine | null,
        public responders: Responder[],
        public supplementals: SupplementalInfo[],
        public hydrants: Hydrant[] | null,
    ) { super(id, creationTime, ts, typeInformation, unitShortNames, synopsis, centroid, address, disposition); }

    isActive(activeTime: number, now: Date): boolean {
        const jobAge = now.getTime() - this.creationTime.getTime();
        return jobAge <= (activeTime * 1000);
    }

    static parse(o: any): ParseResult<ListJob> {
        try {
            const minimalParse = MinimalJob.parse(o);
            if (minimalParse.success == false) {
                return ParseUtils.parseFailure<ListJob>(`Invalid List Job: ${minimalParse.justification}`);
            }

            const minimal = minimalParse.value;

            return ParseUtils.parseSuccess(new ListJob(
                minimal.id,
                minimal.creationTime,
                minimal.ts,
                minimal.typeInformation,
                minimal.unitShortNames,
                minimal.synopsis,
                minimal.centroid,
                ParseUtils.getStringOrNull(o, "crossStreets"),
                minimal.address,
                minimal.disposition,
                ParseUtils.getArrayOfSubobjects(o, "assignments", Assignment.parse, "throw"),
                ParseUtils.getSubobjectOrNull(o, "route", RouteLine.parse),
                ParseUtils.getArrayOfSubobjects<Responder>(o, "responders", Responder.parse, "throw"),
                ParseUtils.getArrayOfSubobjects<SupplementalInfo>(o, "supplementals", SupplementalInfo.parse, "throw"),
                ParseUtils.getArrayOfSubobjectsOrNull<Hydrant>(o, "hydrants", Hydrant.parse, "throw"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<ListJob>(`Invalid ListJob Model: ${e.message}`);
        }
    }

    static compare(j1: ListJob, j2: ListJob): number {
        return j2.creationTime.getTime() - j1.creationTime.getTime() || j1.id.localeCompare(j2.id);
    }
}

export class FullJob extends MinimalJob {
    private constructor(
        public id: string,
        public creationTime: Date,
        public ts: Date,
        public typeInformation: JobTypeInformation,
        public unitShortNames: string[],
        public synopsis: string,
        public centroid: Point | null,
        public address: Address | null,
        public disposition: Disposition,

        public assignments: Assignment[],
        public priority: string | null,
        public box: string | null,
        public crossStreets: string | null,
        public supplementals: SupplementalInfo | null,
        public responders: Responder[],
        public hydrants: Hydrant[] | null,
        public location: GeometryObject,
        public route: LineString,
    ) { super(id, creationTime, ts, typeInformation, unitShortNames, synopsis, centroid, address, disposition); }

    static parse(o: any): ParseResult<FullJob> {
        try {
            const minimalParse = MinimalJob.parse(o);
            if (minimalParse.success == false) {
                return ParseUtils.parseFailure<FullJob>(`Invalid FullJob Model: ${minimalParse.justification}`);
            }

            const minimal = minimalParse.value;

            return ParseUtils.parseSuccess(new FullJob(
                minimal.id,
                minimal.creationTime,
                minimal.ts,
                minimal.typeInformation,
                minimal.unitShortNames,
                minimal.synopsis,
                minimal.centroid,
                minimal.address,
                minimal.disposition,
                ParseUtils.getArrayOfSubobjects(o, "assignments", Assignment.parse, "throw"),
                ParseUtils.getStringOrNull(o, "priority"),
                ParseUtils.getStringOrNull(o, "box"),
                ParseUtils.getStringOrNull(o, "crossStreets"),
                ParseUtils.getSubobjectOrNull(o, "supplementals", SupplementalInfo.parse),
                ParseUtils.getArrayOfSubobjects(o, "responders", Responder.parse, "warn"),
                ParseUtils.getArrayOfSubobjectsOrNull(o, "hydrants", Hydrant.parse, "warn"),
                o["location"],
                o["route"],
            ));
        } catch (e) {
            return ParseUtils.parseFailure<FullJob>(`Invalid FullJob Model: ${e.message}`);
        }
    }

    updateResponders(responders: Responder[]) {
        if (arraysEqual(this.responders, responders, (a, b) => a.isEqual(b))) {
            config.info("Not updating identical responders");
            return;
        }
        this.responders = responders;
    }

    updateUnitShortNames(unitShortNames: string[]) {
        if (arraysEqual(this.unitShortNames, unitShortNames, (a, b) => a == b)) {
            config.info("Not updating identical unitShortNames");
            return;
        }
        this.unitShortNames = unitShortNames;
    }

    updateHydrants(hydrants: Hydrant[]) {
        // FIXME: Update hydrants; Waiting on API implementation
    }
}
