import * as React from 'react';
import {BatteryGauge} from 'react-battery-gauge';
import {Segment} from "semantic-ui-react";

interface DroneInfoOverlayProps {
    altitude: number | null;
    heading: number | null;
    speed: number | null;
    droneBattery: number | null;
    rcBattery: number | null;
    index: number;
}

type DroneInfoState = {
    altitude: number | null,
    heading: number | null,
    speed: number | null,
    droneBattery: number | null,
    rcBattery: number | null,
};

class DroneInfoOverlay extends React.Component<DroneInfoOverlayProps, DroneInfoState> {
    constructor(props: DroneInfoOverlayProps) {
        super(props);
        this.state = {
            altitude: null,
            heading: null,
            speed: null,
            droneBattery: null,
            rcBattery: null,
        };
    }

    componentDidMount() {
        this.setState({
            altitude: this.props.altitude || this.state.altitude,
            heading: this.props.heading || this.state.heading,
            speed: this.props.speed || this.state.speed,
            droneBattery: this.props.droneBattery || this.state.droneBattery,
            rcBattery: this.props.rcBattery || this.state.rcBattery,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<DroneInfoOverlayProps>, nextContext: any) {
        let dataChanged = false;
        // Whenever the drone data is updated, we want to only change the data if something has changed.
        const newState: DroneInfoState = {...this.state};
        if (nextProps.altitude && this.state.altitude != nextProps.altitude) {
            dataChanged = true;
            newState.altitude = Number.parseInt(nextProps.altitude.toFixed(2));
        }
        if (nextProps.heading && this.state.heading != nextProps.heading) {
            dataChanged = true;
            newState.heading = nextProps.heading;
        }
        if (nextProps.speed && this.state.speed != nextProps.speed) {
            dataChanged = true;
            newState.speed = Number.parseInt(nextProps.speed.toFixed(2));
        }
        if (nextProps.droneBattery && this.state.droneBattery != nextProps.droneBattery) {
            dataChanged = true;
            newState.droneBattery = nextProps.droneBattery;
        }
        if (nextProps.rcBattery && this.state.rcBattery != nextProps.rcBattery) {
            dataChanged = true;
            newState.rcBattery = nextProps.rcBattery;
        }
        if (dataChanged) {
            this.setState(newState);
        }
    };

    render() {
        return (
            <Segment className="drone-info-segment">
                <span className="drone-info-line"><b>{`Drone #${this.props.index}`}</b></span>
                <span className="drone-info-line">
          Drone Battery: <BatteryGauge value={this.state.droneBattery || 0} size={100}/>
        </span>
                <span className="drone-info-line">RC Battery: <BatteryGauge value={this.state.rcBattery || 0}
                                                                            size={100}/></span>
                <span className="drone-info-line">{`Altitude: ${this.state.altitude || 0} m`}</span>
                <span className="drone-info-line">{`Ground Speed: ${this.state.speed || 0} m/s`}</span>
            </Segment>
        );
    }
}

export default DroneInfoOverlay;
