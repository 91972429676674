import * as i18n from "i18next";
import * as React from 'react';
import {SyntheticEvent} from "react";
import {Button, DropdownProps, Form, InputProps, Message, Modal, TextAreaProps} from 'semantic-ui-react';
import {config} from "../config";
import {ApiResult, BryxApi} from "../utils/bryxApi";
import {nullIfBlank} from "../utils/functions";

interface ContactSupportModalProps {
    open: boolean;
    onClose: () => void;
}

interface ContactSupportModalState {
    status: {key: "ready"} | {key: "loading"} | {key: "error", description: string};
    from: string | null;
    subject: string | null;
    type: string;
    image: File | null;
    body: string | null;
}

export class ContactSupportModal extends React.Component<ContactSupportModalProps, ContactSupportModalState> {
    private static readonly ticketTypes = ["feedback", "question", "bug", "featureRequest"];

    constructor(props: ContactSupportModalProps, context: any) {
        super(props, context);

        this.state = ContactSupportModal.getInitialState(props);
    }

    private static getInitialState(props: ContactSupportModalProps): ContactSupportModalState {
        return {
            status: {
                key: "ready",
            },
            from: null,
            subject: null,
            type: "feedback",
            image: null,
            body: null,
        };
    }

    private onFromFieldChange(e: SyntheticEvent<any>, inputProps: InputProps) {
        this.setState({
            from: nullIfBlank(inputProps.value),
        });
    }

    private onSubjectChange(e: SyntheticEvent<any>, inputProps: InputProps) {
        this.setState({
            subject: nullIfBlank(inputProps.value),
        });
    }

    private onTypeChange(e: SyntheticEvent<any>, dropdownProps: DropdownProps) {
        this.setState({
            type: dropdownProps.value as string,
        });
    }

    private onBodyChange(e: SyntheticEvent<any>, bodyProps: TextAreaProps) {
        this.setState({
            body: nullIfBlank((bodyProps.value as string) || null),
        });
    }

    private onSubmitSupportTicket() {
        this.setState({
            status: {key: "loading"},
        }, () => {
            if (this.state.from == null || this.state.body == null) {
                this.setState({
                    status: {key: "error", description: i18n.t("general.genericError")},
                });
                return;
            }

            BryxApi.sendSupportTicket(
                this.state.from,
                this.state.subject,
                this.state.type,
                this.state.body,
                this.state.image,
                (result: ApiResult<null>) => {
                    if (result.success == true) {
                        config.info("User successfully sent support ticket");
                        this.setState({
                            status: {key: "ready"},
                            from: null,
                            body: null,
                        }, () => this.resetAndClose());
                    } else {
                        config.warn(`User failed to send support ticket: ${result.debugMessage}`);
                        this.setState({
                            status: {key: "error", description: result.message},
                        });
                    }
                },
            );
        });
    }

    private resetAndClose() {
        this.setState(ContactSupportModal.getInitialState(this.props));
        this.props.onClose();
    }

    render() {
        const ticketTypes = ContactSupportModal.ticketTypes.map((option) => ({
            key: option,
            text: i18n.t(`contactSupport.supportTypes.${option}`),
            value: option,
        }));

        const errorMessage = this.state.status.key == "error" ? (
            <Message negative content={this.state.status.description}/>
        ) : null;

        return (
            <Modal open={this.props.open}
                   size="small"
                   onClose={this.resetAndClose.bind(this)}>
                <Modal.Header>
                    {i18n.t("contactSupport.header")}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input label={i18n.t("contactSupport.fromLabel")}
                                    placeholder={i18n.t("contactSupport.fromPlaceholder")}
                                    autoComplete={false}
                                    autoCorrect={false}
                                    autoCapitalize={false}
                                    spellCheck={false}
                                    value={this.state.from || ""}
                                    onChange={this.onFromFieldChange.bind(this)}/>
                        <Form.Input label={i18n.t("contactSupport.subjectLabel")}
                                    placeholder={i18n.t("contactSupport.subjectPlaceholder")}
                                    value={this.state.subject || ""}
                                    onChange={this.onSubjectChange.bind(this)}/>
                        <Form.Select label={i18n.t("contactSupport.ticketTypeLabel")}
                                     options={ticketTypes}
                                     value={this.state.type}
                                     onChange={this.onTypeChange.bind(this)}/>
                        <Form.Input label={i18n.t("contactSupport.imageLabel")}
                                    type="file"
                                    control="input"
                                    accept=".png,.jpg"
                                    onChange={e => {
                                        const target = e.target as HTMLInputElement;
                                        this.setState({image: target.files != null ? target.files[0] : null});
                                    }}/>
                        <Form.TextArea label={i18n.t("contactSupport.bodyLabel")}
                                       value={this.state.body || ""}
                                       placeholder={i18n.t("contactSupport.bodyPlaceholder")}
                                       onChange={this.onBodyChange.bind(this)}/>
                    </Form>
                    {errorMessage}
                </Modal.Content>
                <Modal.Actions>
                    <Button content={i18n.t("general.cancel")}
                            onClick={this.resetAndClose.bind(this)}/>
                    <Button primary
                            loading={this.state.status.key == "loading"}
                            disabled={this.state.from == null || this.state.body == null || this.state.status.key == "loading"}
                            content={i18n.t("general.send")}
                            onClick={this.onSubmitSupportTicket.bind(this)}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
