import {VideoStreamer} from '@flytnow/video-client';
import * as React from "react";
import {Loader, Segment} from "semantic-ui-react";
import {SessionData} from "../../models/boardUpdate";
import {DroneFlight} from "../../models/drone";
import {ListJob} from "../../models/job";
import {SessionManager, SessionManagerObserver} from "../../utils/sessionManager";

interface JobOverlayDroneProps {
    job: ListJob;
}

interface JobOverlayDroneState {
    loading: boolean | null;
    droneData: DroneFlight | null;
    hasLoadedStream: boolean;
}

export class JobOverlayDrone extends React.Component<JobOverlayDroneProps, JobOverlayDroneState> implements SessionManagerObserver {
    constructor(props: JobOverlayDroneProps, context: any) {
        super(props, context);
        this.state = {
            loading: true,
            droneData: null,
            hasLoadedStream: false,
        };
        SessionManager.shared.registerObserver(this);
    }

    initFeed(apiKey: string, vehicleId: string, sourceId: number, elementId: string) {
        // Instantiating VideoStreamer Object
        // which will expose all the API methods once connection is established
        console.log('Video feed being created');
        const videoFeed = new VideoStreamer(
            apiKey,
            vehicleId,
            sourceId,
            elementId,
        );

        if (videoFeed == null) {
            console.log('Video feed null');
            return;
        }

        // Checking for connection failure
        if (videoFeed['status'] === false) {
            console.log(
                'Something went wrong.',
                videoFeed['code'],
                videoFeed['message'],
            );
        } else {
            // Connection Successful
            console.log(
                'Feed Active for ',
                vehicleId,
                ' with source-id ',
                sourceId,
            );
        }
    }

    sessionManagerDidUpdateData(sessionData: SessionData) {
        this.setState({
            droneData: sessionData.drone,
            loading: false,
        });
        if (sessionData.drone != null && !this.state.hasLoadedStream) {
            const apiKey = 'NjE4YTk4YzUyMjUxMTkwNWExMzRlMTJmNGViZjljNGQ4ODA2OTg1ZDc0NTU1YTllNzA0ZmQwOTQ=';
            const sourceId = 0;
            const elementId = 'drone-feed';
            this.initFeed(apiKey, sessionData.drone.stream, sourceId, elementId);
            this.setState({
                hasLoadedStream: true,
            });
        }
    }

    sessionManagerDidDestroySession(forced: boolean) {
        this.setState({
            droneData: null,
            loading: false,
        });
    }

    render() {
        const {loading, droneData} = this.state;

        return (
            <Segment style={loading == false ? {
                minHeight: "500px",
            } : {}}>
                {loading == true ? (
                        <div style={{marginLeft: "33%"}}>
                            <h3>Drone en route...</h3>
                            <Loader style={{marginLeft: "40px"}} inline active/>
                        </div>
                    )
                : loading == false && droneData ? (
                    <div
                        id="drone-feed"
                        src={droneData.stream}
                        alt="drone flight"
                        style={{display: "block", width: "100%", height: "auto", margin: "auto", borderRadius: ".28571429rem"}}
                    />
                    ) : (
                        <h3>Drone not deployed.</h3>
                    )}
                {/* inferenceResults ? <hr /> : null */}
                {/* inferenceResults */}
            </Segment>
        );
    }
}
