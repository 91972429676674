import {ParseUtils} from "@bryxinc/lunch";
import {LatLngBounds} from "leaflet";
import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {StationBoardConfig} from "./board";
import {DroneFlight} from "./drone";
import {Message} from "./message";

export type VideoFeedState = {
    scuId: string;
    currentFrame?: string;
};

export class SessionData {
    public constructor(public sessionName: string,
                       public board: StationBoardConfig,
                       public message: Message | null,
                       public drone: DroneFlight | null,
                       public agencyBounds: LatLngBounds,
                       public videoFeed: VideoFeedState | null) { }
}

// BoardUpdate

export type BoardUpdateInitialData = {
    type: "initialData",
    sessionName: string,
    board: StationBoardConfig,
    message: Message | null,
    agencyBounds: LatLngBounds,
};

export type BoardUpdateConfig = {
    type: "config",
    board: StationBoardConfig,
};
export type BoardUpdateMessage = {
    type: "message",
    message: Message | null,
};
export type BoardUpdateSignOut = {
    type: "signOut",
};
export type BoardUpdateDrone = {
    type: "drone",
    droneId: string,
    flight: DroneFlight,
};
export type BoardUpdateReconnect = {
    type: "reconnect",
    cluster: string,
    scuId: string,
};
export type BoardUpdateVideoFeedStart = {
    type: "videoFeedStart",
    scuId: string,
};
export type BoardUpdateVideoFeedFrame = {
    type: "videoFeedFrame",
    frame: string,
};
export type BoardUpdateVideoFeedStop = {
    type: "videoFeedStop",
    scuId: string,
};
export type BoardUpdate = BoardUpdateConfig | BoardUpdateMessage | BoardUpdateSignOut | BoardUpdateInitialData | BoardUpdateDrone | BoardUpdateReconnect | BoardUpdateVideoFeedStart | BoardUpdateVideoFeedFrame | BoardUpdateVideoFeedStop;
export type LocalBoardMessage = BoardUpdateVideoFeedFrame | BoardUpdateVideoFeedStop | BoardUpdateVideoFeedStart;

export function parseBoardUpdate(message: BryxWebSocketMessage): ApiResult<BoardUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");

                switch (type) {
                    case "config":
                        return apiSuccess({
                            type: "config",
                            board: ParseUtils.getSubobject<StationBoardConfig>(message.data, "board", StationBoardConfig.parse),
                        } as BoardUpdateConfig);
                    case "drone":
                        return apiSuccess({
                            type: "drone",
                            droneId: ParseUtils.getString(message.data, "droneId"),
                            flight: ParseUtils.getSubobject<DroneFlight>(message.data, "flight", DroneFlight.parse),
                        } as BoardUpdateDrone);
                    case "message":
                        return apiSuccess({
                            type: "message",
                            message: ParseUtils.getSubobjectOrNull<Message>(message.data, "message", Message.parse),
                        } as BoardUpdateMessage);
                    case "signOut":
                        return apiSuccess({
                            type: "signOut",
                        } as BoardUpdateSignOut);
                    case "videoFeedStart":
                        return apiSuccess({
                            type: "videoFeedStart",
                            scuId: ParseUtils.getString(message.data, "scuId"),
                        } as BoardUpdateVideoFeedStart);
                    case "videoFeedStop":
                        return apiSuccess({
                            type: "videoFeedStop",
                            scuId: ParseUtils.getString(message.data, "scuId"),
                        } as BoardUpdateVideoFeedStop);
                    case "videoFeedFrame":
                        return apiSuccess({
                            type: "videoFeedFrame",
                            frame: ParseUtils.getString(message.data, "frame"),
                        } as BoardUpdateVideoFeedFrame);
                    case "reconnect":
                        return apiSuccess({
                            type: "reconnect",
                            scuId: ParseUtils.getString(message.data, "scuId"),
                            cluster: ParseUtils.getString(message.data, "cluster"),
                        } as BoardUpdateReconnect);
                    default:
                        return apiFailure(null, `Invalid BoardUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                const boundsList = ParseUtils.getArray(message.initialData, "agencyBounds") as [number];
                if (boundsList.length != 4) {
                    return apiFailure("Invalid agency bounds", "Agency bounds list must be length 4");
                }
                // TODO: fake data
                /*
                if (message.initialData != null) {
                    message.initialData["board"]["screenSaverTimeout"] = 600;
                    message.initialData["board"]["screenSaverMode"] = 'off';
                }
                */
                return apiSuccess({
                    type: "initialData",
                    sessionName: ParseUtils.getString(message.initialData, "sessionName"),
                    board: ParseUtils.getSubobject<StationBoardConfig>(message.initialData, "board", StationBoardConfig.parse),
                    message: ParseUtils.getSubobjectOrNull<Message>(message.initialData, "message", Message.parse),
                    agencyBounds: new LatLngBounds([boundsList[1], boundsList[0]], [boundsList[3], boundsList[2]]),
                } as BoardUpdateInitialData);
            default:
                return apiFailure(null, `Invalid BoardUpdateModel: ${message.key}`);
        }
    } catch (e) {
        return apiFailure(null, `Invalid BoardUpdate Model: ${e.message}`);
    }
}
