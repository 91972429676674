import * as moment from "moment";
import * as React from "react";
import {Progress} from "semantic-ui-react";

interface ProgressAttachmentProps {
    expirationTime: Date;
    turnoutTime: number;
    redTime: number | null;
    now: Date;
}

export class ProgressAttachment extends React.Component<ProgressAttachmentProps, {}> {
    render() {
        const {expirationTime, turnoutTime, redTime} = this.props;

        const nowMoment = moment(this.props.now);
        const durationRemaining = moment.duration(moment(expirationTime).diff(nowMoment));
        const percentTimeRemaining = (durationRemaining.asSeconds() / turnoutTime);

        return (
            <Progress key={`job-progress`}
                      className="progress-attachment"
                      percent={percentTimeRemaining * 100}
                      color={redTime != null && durationRemaining.asSeconds() <= redTime ? "red" : undefined}
                      attached="bottom"/>
        );
    }
}
