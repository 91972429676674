export interface AlertTone {
    soundId: string;
    element: HTMLAudioElement;
}

export class AudioUtils {
    private static rawResourceFromId(id: string): HTMLAudioElement {
        return new Audio(`/resources/audio/${id}.mp3`);
    }

    public static supportedSoundIds = [
        "standard",
        "standard1k",
        "continuous",
        "station51",
        "dtmf",
        "henf",
        "251",
        "254",
        "068",
        "1465_950",
        "standard_long",
        "sd_10_long",
    ];
    private static supportedTones: AlertTone[] = AudioUtils.supportedSoundIds.map(
        (id) => ({ soundId: id, element: AudioUtils.rawResourceFromId(id) })
    );

    public static resourceFromSoundId(id: string): HTMLAudioElement {
        const matchedSounds = AudioUtils.supportedTones.filter(
            (tone) => tone.soundId == id
        );
        if (matchedSounds.length != 0) {
            return matchedSounds[0].element;
        } else {
            return AudioUtils.supportedTones[0].element;
        }
    }

    public static clickSound = AudioUtils.rawResourceFromId("click");
    public static jobSound = AudioUtils.rawResourceFromId("bing");
}
