import * as React from "react";
import {Icon} from "semantic-ui-react";

type AlertOverlayProps = {
    alert: string | null;
};

type AlertOverlayState = {
    alert: string | null;
    isError: boolean;
};

interface DroneErrorCodeInterface {
    [key: string]: string;
}

const DroneErrorCode: DroneErrorCodeInterface = {
    0: 'No Error',
    1: 'FlytNow authentication failed',
    2: 'Take Off Request Failed',
    3: 'Land Request Failed',
    4: 'RTL Request Failed',
    5: 'Get Connection State Failed',
    6: 'Get GPS Location Failed',
    7: 'Get Local Position Information Failed',
    8: 'Get Battery Information Failed',
    9: 'Get Craft Attitude Failed',
    10: 'Get Zoom Status Failed',
    11: 'Set Zoom Status Failed',
    12: 'Set Gimbal YPR failed',
    13: 'Go To GPS Location Failed',
    14: 'Create Basic Waypoint Mission Failed',
    15: 'Execute Basic Waypoint Mission Failed',
    16: 'Create Advanced Waypoint Mission Failed',
    17: 'Execute Advanced Waypoint Mission Failed',
    18: 'Poll Telemetry Failed',
    19: 'Drone Connection Timeout',
    20: 'Drone Disconnected',
    21: 'Telemetry Mission Timeout',
    22: 'FlytBase API Authentication Failed',
};

// These error codes will be decorated with a yellow background/black text
const WarningErrorCodes = ["19", "20", "21"];

class AlertOverlay extends React.Component<AlertOverlayProps, AlertOverlayState> {
    constructor(props: AlertOverlayProps) {
        super(props);
        this.state = {
            alert: null,
            isError: false,
        };
    }

    componentDidMount() {
        this.setState({alert: this.props.alert || "0", isError: this.props.alert !== "0"});
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<AlertOverlayProps>, nextContext: any) {
        if (nextProps.alert !== null && nextProps.alert !== this.state.alert) {
            this.setState({alert: nextProps.alert, isError: nextProps.alert !== "0"});
        }
    }

    private errorCodeDisplay() {
        let alertStyle = {};
        if (this.state.alert && WarningErrorCodes.indexOf(this.state.alert!) != -1) {
            alertStyle = {
                display: 'inline-flex',
                alignItems: 'center',
                backgroundColor: 'yellow',
                maxHeight: '100px',
                border: '5px solid black',
                borderRadius: '30px',
                color: 'black',
            };
        } else {
            alertStyle = {
                display: 'inline-flex',
                alignItems: 'center',
                backgroundColor: 'red',
                maxHeight: '100px',
                border: '5px solid black',
                borderRadius: '30px',
                color: 'white',
            };
        }

        return (
            <div className="error-code-display" style={alertStyle}>
                <Icon name="warning circle" size="huge"/>
                <p style={{fontSize: '40px', marginRight: '10px'}}>
                    {this.state.alert && `Error Code ${this.state.alert}: ${DroneErrorCode[this.state.alert]}`}
                </p>
            </div>
        );
    }

    render() {
        return (
            <div className="error-container">
                {this.state.alert && this.state.isError ? this.errorCodeDisplay() : null}
            </div>
        );
    }
}

export default AlertOverlay;
