import * as i18n from "i18next";
import * as moment from "moment";
import * as React from "react";
import {Segment} from "semantic-ui-react";
import {ListJob} from "../../models/job";
import {BryxColors} from "../../utils/bryxColors";
import {DateUtils} from "../../utils/dateUtils";
import {resourceFromJobType} from "../../utils/functions";
import {GeoUtils} from "../../utils/geoUtils";
import {ProgressAttachment} from "./progressAttachment";

interface JobOverlayDetailProps {
    job: ListJob;
    expirationTime: Date;
    activeTime: number;
    redTime: number | null;
    now: Date;
    attachProgress: boolean;
}

export class JobOverlayDetail extends React.Component<JobOverlayDetailProps, {}> {
    private static UNIT_TEXT_SIZE = "30px";
    private static JOB_DETAILS_TEXT_SIZE = "26px";

    render() {
        const {job} = this.props;

        let locationDisplay = "location unknown";
        let locationInfoDisplay = null;

        if (job.address != null) {
            locationDisplay = job.address.street;
            if (job.address.locationInfo) {
                locationInfoDisplay = job.address.locationInfo;
            }
        }

        return (
            <Segment>
                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start"}}>
                    <img src={resourceFromJobType(job.typeInformation.type)} alt="job-type"/>
                    <div style={{marginLeft: "15px", flex: 1}}>
                        <span style={{fontSize: JobOverlayDetail.UNIT_TEXT_SIZE, lineHeight: JobOverlayDetail.UNIT_TEXT_SIZE}}><b>{job.unitShortNames.join(", ")}</b> - {job.synopsis}</span>
                        <hr/>
                        <div style={{fontSize: JobOverlayDetail.JOB_DETAILS_TEXT_SIZE, marginBottom: "6px", wordBreak: "break-word", lineHeight: JobOverlayDetail.JOB_DETAILS_TEXT_SIZE}}>
                            <b>{locationDisplay}</b>{locationInfoDisplay ? ' - ' + locationInfoDisplay : undefined}
                            <br />
                            {job.crossStreets ? `between ${job.crossStreets}` : undefined}
                        </div>
                        {job.route != null ? (
                            <div style={{fontSize: JobOverlayDetail.JOB_DETAILS_TEXT_SIZE, marginBottom: "6px"}}>
                                {job.route.distance != null ? (
                                    <span>{GeoUtils.metersToMi(job.route.distance).toFixed(1)} {i18n.t("general.mi")}</span>
                                ) : null}
                                {job.route.distance != null && job.route.duration != null ? (
                                    <span>&nbsp;&bull;&nbsp;</span>
                                ) : null}
                                {job.route.duration != null ? (
                                    <span>{i18n.t("general.eta")} {moment.utc(job.route.duration * 1000).format('HH:mm:ss')}</span>
                                ) : null}
                            </div>
                        ) : null}
                        <div style={{fontSize: JobOverlayDetail.JOB_DETAILS_TEXT_SIZE, color: BryxColors.lightGray}}>
                            {DateUtils.formatDateTime(job.creationTime)}
                        </div>
                    </div>
                </div>
                {this.props.attachProgress ? (
                    <ProgressAttachment expirationTime={this.props.expirationTime} redTime={this.props.redTime} turnoutTime={this.props.activeTime} now={this.props.now} />
                ) : null}
            </Segment>
        );
    }

}
