import * as React from "react";
import {Card} from "semantic-ui-react";
import {UnitStatus, UnitStatusData} from "../../../models/idleOverlays";

interface UnitStatusInfoOverlayProps {
    units: UnitStatusData[];
}

export class UnitStatusInfoOverlay extends React.Component<UnitStatusInfoOverlayProps, {}> {
    private getColorForStatus(s: UnitStatus) {
        switch (s) {
            case UnitStatus.inService:
                return "#0f0";
            case UnitStatus.outOfService:
            case UnitStatus.onScene:
            case UnitStatus.transporting:
                return "#f00";
            case UnitStatus.partialAvailable:
                return "#ff0";
            case UnitStatus.enRoute:
                return "#ff9900";
        }
    }

    private getLetterForState(s: UnitStatus) {
        switch (s) {
            case UnitStatus.inService:
                return "A";
            case UnitStatus.outOfService:
            case UnitStatus.onScene:
            case UnitStatus.transporting:
                return "U";
            case UnitStatus.partialAvailable:
                return "P";
            case UnitStatus.enRoute:
                return "E";
        }
    }

    render() {
        return (
            <Card>
                <Card.Content style={{display: "inline-flex", flexDirection: "row"}}>
                    {this.props.units.map(u => (
                        <h2 style={{textAlign: "center", marginTop: "1em", width: `${(1 / this.props.units.length) * 100}%`}}>
                            {u.name}
                            <br />
                            <div style={{font: "caption", fontWeight: "bold", textAlign: "center", verticalAlign: "middle", margin: "0.5em auto 0 auto", paddingTop: "0.25em", width: "1.5em", height: "1.5em", backgroundColor: this.getColorForStatus(u.status), borderRadius: "100%"}}>
                                {this.getLetterForState(u.status)}
                            </div>
                        </h2>
                    ))}
                </Card.Content>
            </Card>
        );
    }
}
