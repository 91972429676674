import {ParseUtils} from "@bryxinc/lunch";
import {ILog} from "__LSL/ILog";
import {ILocalStorageLoggerConfiguration} from "__LSL/loggers/ILocalStorageLoggerConfiguration";
import {ConsoleLogger, DefaultFormatter, LocalStorageLogger, NullLogger} from 'localstorage-logger';

declare const VERSION: string;
declare const SCU_BUILD: boolean;

type ConfigType = "debug" | "dev" | "prod";
type ServerType = "dev" | "k8s" | "staging" | "prod";

export interface Config {
    baseUrl: string;
    configType: ConfigType;
    serverType: ServerType;
    scuBuild: boolean;
    version: string;
    log: ILog;
    debug: (...args: any[]) => any;
    info: (...args: any[]) => any;
    warn: (...args: any[]) => any;
    error: (...args: any[]) => any;
}

function initConfig(): Config {
    // clear out local storage before using the local storage logger
    Object.keys(localStorage)
        .filter(key => key.indexOf('bryx-dev-log') == 0 || key.indexOf('bryx-prod-log') == 0)
        .forEach(key => { localStorage.removeItem(key); });

    let serverType: ServerType;
    let configType: ConfigType;
    let logConfig: ILocalStorageLoggerConfiguration;

    switch (process.env.NODE_ENV) {
        case "debug":
            console.log("Debug config loaded");
            logConfig = {
                logName: 'bryx-dev-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "dev";
            configType = "debug";
            break;
        case "dev":
            console.log("Dev config loaded");
            logConfig = {
                logName: 'bryx-dev-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "dev";
            configType = "dev";
            break;
        case "k8s":
            console.log("K8s config loaded");
            logConfig = {
                logName: 'bryx-prod-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "k8s";
            configType = "prod";
            break;
        case "production":
            logConfig = {
                logName: 'bryx-prod-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "prod";
            configType = "prod";
            break;
        default:
            throw new Error(`Bad deployment: ${process.env.NODE_ENV} -- No config for this deployment`);
    }

    const formatter = new DefaultFormatter();
    const chainTerminal = new NullLogger();
    // Don't include console logger if config is prod
    const consoleLogChain = configType != "prod" ? new ConsoleLogger(formatter, chainTerminal) : chainTerminal;
    const localStorageLogChain = new LocalStorageLogger(logConfig, consoleLogChain);

    const write = (level: number, args: any[]) => {
        if (configType == "prod" && level <= 1) {
            // Don't store debug messages on prod
            return;
        }
        const time = new Date().toISOString();
        const jsonMessage = JSON.stringify(args);
        const jsonMessageWithoutBrackets = jsonMessage.slice(1, jsonMessage.length - 1);
        if (jsonMessage.length >= 4096) {
            // don't exceed our local storage quota. it's as small as 65536, but i don't want to get anywhere
            // close to that
            return;
        }
        localStorageLogChain.log({
            level, time, message: jsonMessageWithoutBrackets,
        });
    };

    const logger: ILog = {
        debug(...args) { write(1, args); },
        info(...args) { write(2, args); },
        warn(...args) { write(3, args); },
        error(...args) { write(4, args); },
        exportToArray() { return localStorageLogChain.allEntries().map(entry => formatter.format(entry)); },
    };

    ParseUtils.onWarn = m => logger.error(m);

    return {
        baseUrl: process.env.BASE_URL || '',
        configType: configType,
        serverType: serverType,
        version: VERSION,
        scuBuild: SCU_BUILD,
        log: logger,
        debug: logger.debug,
        info: logger.info,
        warn: logger.warn,
        error: logger.error,
    };
}

export let config = initConfig();
