import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {Point} from "geojson";

export enum MapClientType {
    user, apparatus,
}

type MapClientInfo = {
    type: MapClientType,
    commonName: string,
    initials: string,
};

export class MapClient {
    public constructor(
        public id: string,
        public info: MapClientInfo,
        public location: Point,
        public lastUpdated: Date,
    ) {}

    get name(): string {
        return this.info.commonName;
    }

    get initials(): string {
        return this.info.initials;
    }

    static parse(o: any): ParseResult<MapClient> {
        try {
            return ParseUtils.parseSuccess(new MapClient(
                ParseUtils.getString(o, "id"),
                {
                    type: ParseUtils.getEnum(o, "type", MapClientType),
                    commonName: ParseUtils.getString(o, "name"),
                    initials: ParseUtils.getString(o, "initials"),
                },
                o["location"],
                ParseUtils.getUNIXTimestampDate(o["location"]["properties"]["lastUpdated"], "sec"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MapClient>(`Invalid MapClient Model: ${e.message}`);
        }
    }
}
