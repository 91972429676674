import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {LineString, Point} from "geojson";

export class DroneFlight {
  private constructor(
    public stream: string,
    public path: LineString | null,
    public location: Point | null,
    public altitude: number | null,
    public speed: number | null,
    public heading: number | null,
    public droneBattery: number | null,
    public rcBattery: number | null,
    public error: string | null,
  ) {
  }

  static parse(o: any): ParseResult<DroneFlight> {
    try {
      return ParseUtils.parseSuccess(new DroneFlight(
        ParseUtils.getString(o, "stream"),
        o["path"],
        o["location"],
        ParseUtils.getNumberOrNull(o, "altitude"),
        ParseUtils.getNumberOrNull(o, "speed"),
        ParseUtils.getNumberOrNull(o, "heading"),
        ParseUtils.getNumberOrNull(o, "droneBattery"),
        ParseUtils.getNumberOrNull(o, "rcBattery"),
        ParseUtils.getStringOrNull(o, "error"),
      ));
    } catch (e) {
      return ParseUtils.parseFailure<DroneFlight>(`Invalid DroneFlight Model: ${e.message}`);
    }
  }
}
