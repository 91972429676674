import {Point} from "geojson";
import {latLng, LatLng, LatLngBounds, LatLngTuple} from "leaflet";

export class GeoUtils {

    public static geoJsonToLatLng(point: Point): LatLng {
        const flippedCoords = point.coordinates.slice(0, 2).reverse();
        return latLng(flippedCoords as [number, number]);
    }

    public static geoJsonPointIsEqual(a: Point | null, b: Point | null): boolean {
        if (a == null || b == null) {
            return false;
        }
        return latLng(a.coordinates as LatLngTuple).distanceTo(latLng(b.coordinates as LatLngTuple)) < 0.5;
    }

    public static metersToMi(mDistance: number): number {
        return mDistance / 1609.344;
    }

    public static boundsFromLatLngs(latLngs: [[number, number]]): LatLngBounds | null {
        const uniqueLatLngs: Array<[number, number]> = [];
        latLngs.forEach(original => {
            const matchs = uniqueLatLngs.filter(searchLatLng => latLng(searchLatLng).distanceTo(latLng(original)) < 0.5);
            if (matchs.length == 0) {
                uniqueLatLngs.push(original);
            }
        });

        if (uniqueLatLngs.length == 0) {
            return null;
        } else if (uniqueLatLngs.length == 1) {
            const coord = uniqueLatLngs[0];
            return new LatLng(coord[0], coord[1]).toBounds(100);
        } else {
            const swLat = Math.min(...uniqueLatLngs.map(ll => ll[0]));
            const swLng = Math.min(...uniqueLatLngs.map(ll => ll[1]));
            const neLat = Math.max(...uniqueLatLngs.map(ll => ll[0]));
            const neLng = Math.max(...uniqueLatLngs.map(ll => ll[1]));
            return new LatLngBounds(new LatLng(swLat, swLng), new LatLng(neLat, neLng));
        }
    }
}
