import {config} from "../config";
import {LayerType, MapLayer} from "../models/map";
import {MapUpdate} from "../models/mapUpdate";
import {ApiResult, BryxApi} from "./bryxApi";
import {arraysEqual} from "./functions";

export interface MapManagerObserver {
    mapManagerDidUpdateLayers(layers: MapLayer[]): void;
}

export class MapSubscriptionParams {
    private static readonly COORD_TOLERANCE = 0.0000001;

    constructor(public topRight: number[],
                public bottomLeft: number[],
                public layers: LayerType[]) { }

    private static numberCompare(c1: number, c2: number): boolean {
        return Math.abs(c1 - c2) <= MapSubscriptionParams.COORD_TOLERANCE;
    }

    isEqual(params: MapSubscriptionParams) {
        return arraysEqual(this.topRight, params.topRight, MapSubscriptionParams.numberCompare) &&
            arraysEqual(this.bottomLeft, params.bottomLeft, MapSubscriptionParams.numberCompare) &&
            arraysEqual(this.layers, params.layers, (e1, e2) => e1 == e2);
    }
}

export class MapManager {
    private static MAP_WS_KEY = "MapManager-board";

    private subscriptionParams: MapSubscriptionParams | null = null;
    private subscribed = false;
    private observers: MapManagerObserver[] = [];

    public layers: MapLayer[] | null = null;

    static shared = new MapManager();

    public setParams(topRightBounds: number[], bottomLeftBounds: number[], layers: LayerType[]) {
        const newParams = new MapSubscriptionParams(topRightBounds, bottomLeftBounds, layers);
        if (this.subscriptionParams != null && this.subscriptionParams.isEqual(newParams)) {
            return;
        }
        this.subscriptionParams = newParams;
        if (this.subscribed) {
            BryxApi.changeMapSubscription(MapManager.MAP_WS_KEY, topRightBounds, bottomLeftBounds, layers);
        } else {
            this.subscribed = true;
            BryxApi.subscribeToMap(MapManager.MAP_WS_KEY, topRightBounds, bottomLeftBounds, layers, this.onMapUpdate.bind(this));
        }
    }

    public reset() {
        BryxApi.unsubscribe(MapManager.MAP_WS_KEY);
        this.subscribed = false;
        this.subscriptionParams = null;
        this.layers = null;
    }

    private onMapUpdate(result: ApiResult<MapUpdate>) {
        if (result.success == true) {
            switch (result.value.key) {
                case "replace":
                    const newLayers = result.value.layers;
                    this.layers = newLayers;
                    this.observers.forEach(o => o.mapManagerDidUpdateLayers(newLayers));
                    break;
                case "add":
                    if (this.layers == null) {
                        config.error("Failed to process 'add' layer; no existing layers");
                        return;
                    }
                    const targetAddLayerType = result.value.layer;
                    const targetAddLayer = this.layers.filter(l => l.type == targetAddLayerType)[0];
                    if (targetAddLayer == null) {
                        config.error("Failed to process 'add' layer; could not find target layer");
                        return;
                    }
                    const existingItemIndex = targetAddLayer.items.map(i => i.id).indexOf(result.value.item.id);
                    if (existingItemIndex != -1) {
                        targetAddLayer.items[existingItemIndex] = result.value.item;
                    } else {
                        targetAddLayer.items.push(result.value.item);
                    }
                    this.observers.forEach(o => o.mapManagerDidUpdateLayers(this.layers || []));
                    break;
                case "remove":
                    if (this.layers == null) {
                        config.error("Failed to process 'add' layer; no existing layers");
                        return;
                    }
                    const targetRemoveLayerType = result.value.layer;
                    const targetRemoveLayer = this.layers.filter(l => l.type == targetRemoveLayerType)[0];
                    if (targetRemoveLayer == null) {
                        config.error("Failed to process 'remove' layer; could not find target layer");
                        return;
                    }
                    const removalId = result.value.id;
                    targetRemoveLayer.items = targetRemoveLayer.items.filter(i => i.id != removalId);
                    this.observers.forEach(o => o.mapManagerDidUpdateLayers(this.layers || []));
                    break;
            }
        } else {
            config.warn(`Map list websocket failed: ${result.debugMessage}`);
        }
    }

    // MapManagerObservers

    public registerObserver(observer: MapManagerObserver) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }

    public unregisterObserver(observer: MapManagerObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
