import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {apiFailure, ApiResult, apiSuccess} from "../utils/bryxApi";
import {BryxWebSocketMessage} from "../utils/bryxWebSocket";
import {getParseFunction, LayerType, MapLayer, MapLayerItem} from "./map";

// MapUpdate

export type MapUpdateReplace = {
    key: "replace",
    layers: MapLayer[],
};
export type MapUpdateRemove = {
    key: "remove",
    id: string,
    layer: LayerType,
};
export type MapUpdateAdd = {
    key: "add",
    item: MapLayerItem,
    layer: LayerType,
};
export type MapUpdate = MapUpdateReplace | MapUpdateRemove | MapUpdateAdd;

export function parseMapUpdate(message: BryxWebSocketMessage): ApiResult<MapUpdate | null> {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");
                const layer = ParseUtils.getEnum<LayerType>(message.data, "layer", LayerType);

                switch (type) {
                    case "update":
                        let parseFunction: ((o: any) => ParseResult<any>) | null;

                        parseFunction = getParseFunction(layer);
                        if (parseFunction == null) {
                            return apiFailure(null, `Invalid MapUpdate layer type: ${type}`);
                        }

                        return apiSuccess<MapUpdateAdd>({
                            key: "add",
                            layer: layer,
                            item: ParseUtils.getSubobject(message.data, "item", parseFunction),
                        });
                    case "remove":
                        return apiSuccess({
                            key: "remove",
                            layer: layer,
                            id: ParseUtils.getString(message.data, "id"),
                        } as MapUpdateRemove);
                    default:
                        return apiFailure(null, `Invalid MapUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }

                return apiSuccess<MapUpdateReplace>({
                    key: "replace",
                    layers: ParseUtils.getArrayOfSubobjects(message, "initialData", MapLayer.parse, "throw"),
                });
            default:
                return apiSuccess(null);
        }
    } catch (e) {
        return apiFailure(null, `Invalid MapUpdate Model: ${e.message}`);
    }
}
