import {ParseResult, ParseUtils} from "@bryxinc/lunch";
import {MultiPolygon, Point, Polygon} from "geojson";
import {JobTypeInformation} from "./jobTypeInformation";
import {MapClient, MapClientType} from "./mapClient";

export enum LayerType {
    hydrants, users, apparatus, self, jobs, stations, agencies, agencyOpenJobs,
}

export class MapLayer {
    public constructor(
        public type: LayerType,
        public items: MapLayerItem[],
        public success: boolean,
        public reason: string | null,
    ) {}

    static parse(o: any): ParseResult<MapLayer> {
        try {
            const type = ParseUtils.getEnum<LayerType>(o, "type", LayerType);
            const parseFunction = getParseFunction(type);

            if (parseFunction == null) {
                return ParseUtils.parseFailure(`Unable to parse Layer type ${type}`);
            }

            return ParseUtils.parseSuccess(new MapLayer(
                type,
                ParseUtils.getArrayOfSubobjects(o, "items", parseFunction, "throw"),
                ParseUtils.getBoolean(o, "success"),
                ParseUtils.getStringOrNull(o, "reason"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<MapLayer>(`Invalid MapLayer Model: ${e.message}`);
        }
    }
}

export type MapLayerItem = HydrantLayerItem | ClientLayerItem | JobLayerItem | StationLayerItem | AgencyLayerItem;

export class HydrantLayerItem {
    public itemType: "hydrant" = "hydrant";

    public constructor(
        public id: string,
        public location: Point,
        public mainSize: number | null,
        public color: string,
    ) {}

    static parse(o: any): ParseResult<HydrantLayerItem> {
        try {
            return ParseUtils.parseSuccess(new HydrantLayerItem(
                ParseUtils.getString(o, "id"),
                o["location"],
                ParseUtils.getNumberOrNull(o, "mainSize"),
                ParseUtils.getString(o, "color"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<HydrantLayerItem>(`Invalid HydrantLayerItem Model: ${e.message}`);
        }
    }
}

export class ClientLayerItem {
    public itemType: "client" = "client";

    public constructor(
        public id: string,
        public location: Point,
        public initials: string,
        public name: string,
        public ts: Date,
        public type: MapClientType,
    ) {}

    static parse(o: any): ParseResult<ClientLayerItem> {
        try {
            return ParseUtils.parseSuccess(new ClientLayerItem(
                ParseUtils.getString(o, "id"),
                o["location"],
                ParseUtils.getString(o, "initials"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getUNIXTimestampDate(o, "ts"),
                ParseUtils.getEnum(o, "type", MapClientType, MapClientType.user),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<ClientLayerItem>(`Invalid ClientLayerItem Model: ${e.message}`);
        }
    }

    public toMapClient(): MapClient {
        return new MapClient(this.id, {
            type: this.type,
            commonName: this.name,
            initials: this.initials,
        }, this.location, this.ts);
    }
}

export class JobLayerItem {
    public itemType: "job" = "job";

    public constructor(
        public id: string,
        public location: Point,
        public unitShortNames: string[],
        public type: JobTypeInformation,
    ) {}

    static parse(o: any): ParseResult<JobLayerItem> {
        try {
            return ParseUtils.parseSuccess(new JobLayerItem(
                ParseUtils.getString(o, "id"),
                o["location"],
                ParseUtils.getArray(o, "unitShortNames") as string[],
                ParseUtils.getSubobject(o, "type", JobTypeInformation.parse),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<JobLayerItem>(`Invalid JobLayerItem Model: ${e.message}`);
        }
    }
}

export class StationLayerItem {
    public itemType: "station" = "station";

    public constructor(
        public id: string,
        public location: Point,
        public name: string,
    ) {}

    static parse(o: any): ParseResult<StationLayerItem> {
        try {
            return ParseUtils.parseSuccess(new StationLayerItem(
                ParseUtils.getString(o, "id"),
                o["location"],
                ParseUtils.getString(o, "name"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<StationLayerItem>(`Invalid StationLayerItem Model: ${e.message}`);
        }
    }
}

export class AgencyLayerItem {
    public itemType: "agency" = "agency";

    public constructor(
        public id: string,
        public location: Polygon | MultiPolygon,
        public name: string,
    ) {}

    static parse(o: any): ParseResult<AgencyLayerItem> {
        try {
            return ParseUtils.parseSuccess(new AgencyLayerItem(
                ParseUtils.getString(o, "id"),
                o["location"],
                ParseUtils.getString(o, "name"),
            ));
        } catch (e) {
            return ParseUtils.parseFailure<AgencyLayerItem>(`Invalid AgencyLayerItem Model: ${e.message}`);
        }
    }
}

export function getParseFunction(o: LayerType): ((o: any) => ParseResult<MapLayerItem>) | null {
    switch (o) {
        case LayerType.hydrants:
            return HydrantLayerItem.parse;
        case LayerType.users:
        case LayerType.apparatus:
        case LayerType.self:
            return ClientLayerItem.parse;
        case LayerType.jobs:
        case LayerType.agencyOpenJobs:
            return JobLayerItem.parse;
        case LayerType.stations:
            return StationLayerItem.parse;
        case LayerType.agencies:
            return AgencyLayerItem.parse;
        default:
            return null;
    }
}
