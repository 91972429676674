import * as React from 'react';

/***
 * A component that displays the clock based on the local machines time
 * @props all props are optional will default to std fontSize and standard time instead of 24hr
 * size: number just textSize css optional
 * hour24: boolean display AM:PM format vs 24hr
 */

interface ClockProps {
    size?: number;
    hour24?: boolean;
}

class Clock extends React.Component<ClockProps, any> {
    timerID: number;

    state = {
        date: new Date(),
    };

    componentDidMount() {
        this.timerID = window.setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date(),
        });
    }
    render() {
        const options = { hour12: this.props.hour24 ? false : true };
        return (
            <div style={{textAlign: 'center', margin: 'auto', fontWeight: 'bolder', fontSize: this.props.size || undefined}}>
                {this.state.date.toLocaleTimeString('en-US', options)}
            </div>
        );
    }
}

export default Clock;
