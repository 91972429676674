import * as React from "react";
import {Segment} from "semantic-ui-react";
import {VideoFeedState} from "../../../models/boardUpdate";

interface JobOverlayDroneProps {
    videoFeed: VideoFeedState;
}

export class JobOverlayDoorbell extends React.Component<JobOverlayDroneProps, { }> {
    constructor(props: JobOverlayDroneProps, context: any) {
        super(props, context);
    }

    render() {
        const {videoFeed: { currentFrame } } = this.props;

        return (
            <Segment style={{ minHeight: "500px", position: "absolute", top: 0, right: "1vw", textAlign: "center" }}>
                <h2>Doorbell</h2>
                {currentFrame && (
                    <img
                        id="doorbel-feed"
                        src={`data:image/png;base64, ${currentFrame}`}
                        alt="doorbell feed"
                        style={{display: "block", width: "100%", height: "auto", margin: "auto", borderRadius: ".28571429rem"}}
                    />
                )}
            </Segment>
        );
    }
}
