import { ParseResult, ParseUtils } from "@bryxinc/lunch";
import {
  ClientConfig,
  HttpClient,
  HttpRequest,
  HttpResponse,
  ResponseStatus,
} from "@bryxinc/lunch/spoonClient";
import { config } from "../config";
import { Auth as AuthModel } from "../models/auth";
import { StationBoardInactiveLayer } from "../models/board";
import {
  BoardUpdate,
  BoardUpdateVideoFeedFrame,
  BoardUpdateVideoFeedStart, BoardUpdateVideoFeedStop,
  parseBoardUpdate
} from "../models/boardUpdate";
import { ApiError } from "../models/bryxTypes";
import { Eula } from "../models/eula";
import {
  IdleLayersUpdate,
  parseIdleLayersUpdate,
} from "../models/idleLayersUpdate";
import {
  parseIdleOverlayUpdate,
  WeatherForecastType,
  WeatherUpdate,
} from "../models/idleOverlays";
import { JobsListUpdate, parseJobsListUpdate } from "../models/jobsListUpdate";
import { LayerType } from "../models/map";
import { MapUpdate, parseMapUpdate } from "../models/mapUpdate";
import { ServerTime } from "../models/serverTime";
import { SiteSurvey } from "../models/siteSurvey";
import { StreetViewResult } from "../models/streetViewResult";
import { BryxLocal } from "./bryxLocal";
import { BryxWebSocket } from "./bryxWebSocket";
import { DateUtils } from "./dateUtils";
import { DeviceUtils } from "./deviceUtils";
import {SessionManager} from "./sessionManager";
import { SupportUtils } from "./supportUtils";
import i18n = require("i18next");

export type ApiResult<T> =
  | { success: true; value: T }
  | { success: false; message: string; debugMessage: string | null };

export function apiSuccess<T>(value: T): ApiResult<T> {
  return { success: true, value: value };
}

export function apiFailure<T>(
  message: string | null,
  debugMessage: string | null
): ApiResult<T> {
  return {
    success: false,
    message: message || i18n.t("general.genericError"),
    debugMessage: debugMessage,
  };
}

function nullParser(_: any): ParseResult<null> {
  return ParseUtils.parseSuccess(null);
}

/*
function apiArrayResultFromParse<T>(response: HttpResponse, parseFunction: (o: any) => ParseResult<T>, failBehavior: "ignore" | "warn" | "throw"): ApiResult<T[]> {
    if (response.status == ResponseStatus.Success) {
        const itemsObject = { items: response.responseJson };
        return apiSuccess(ParseUtils.getArrayOfSubobjects(itemsObject, 'items', parseFunction, failBehavior));
    } else {
        return apiFailureWithResponse<T[]>(response);
    }
}
*/

function apiResultFromParse<T>(
  response: HttpResponse,
  parseFunction: (o: any) => ParseResult<T>
): ApiResult<T> {
  if (response.status == ResponseStatus.Success) {
    const parseResult = parseFunction(response.responseJson);
    if (parseResult.success == true) {
      return apiSuccess(parseResult.value);
    } else {
      return apiFailure<T>(null, parseResult.justification);
    }
  } else {
    return apiFailureWithResponse<T>(response);
  }
}

function apiFailureWithResponse<T>(response: HttpResponse): ApiResult<T> {
  if (response.status == ResponseStatus.ConnectionFailure) {
    return apiFailure<T>(
      i18n.t("general.connectionFailure"),
      "Connection failure"
    );
  }
  const errorResult = ApiError.parse(response.responseJson);
  if (errorResult.success == true) {
    return apiFailure<T>(
      errorResult.value.message,
      errorResult.value.realCause || errorResult.value.message
    );
  } else {
    return apiFailure<T>(null, errorResult.justification);
  }
}

export class BryxApi {
  private static readonly apiRoot: string = `https://${config.baseUrl}/api/2.2`;
  public static readonly wsUrl: string = `wss://${config.baseUrl}/api/2.2`;
  private static readonly serviceUrlPrefix =
    config.serverType != "prod" && config.serverType != "k8s"
      ? `${config.serverType}-`
      : "";
  public static readonly managementSiteUrl: string = `https://${BryxApi.serviceUrlPrefix}manage.${config.baseUrl}/station-boards`;
  public static onUnauthenticated: () => void;

  private static http: HttpClient = (() => {
    const httpClientConfig: ClientConfig = {
      baseUrl: BryxApi.apiRoot,
      transformRequest: (request: HttpRequest) => {
        const headers = request.headers || {};

        const currentApiKey = BryxLocal.getApiKey();
        if (currentApiKey != null) {
          headers["X-API-KEY"] = currentApiKey;
        }

        headers["X-BRYX-TYPE"] = "stationBoard";

        if (request.body != null) {
          headers["content-type"] = "application/json";
        }

        request.headers = headers;
        return request;
      },
    };
    return new HttpClient(httpClientConfig);
  })();

  private static unauthenticatedCallback(
    callback: (request: HttpRequest, response: HttpResponse) => void
  ): (request: HttpRequest, response: HttpResponse) => void {
    return (request: HttpRequest, response: HttpResponse) => {
      if (
        response.status == ResponseStatus.Unauthorized &&
        BryxApi.onUnauthenticated != null
      ) {
        BryxApi.onUnauthenticated();
      }
      callback(request, response);
    };
  }

  private static authCallback(
    _: HttpRequest,
    response: HttpResponse,
    callback: (result: ApiResult<AuthModel>) => void
  ): void {
    if (response.status == ResponseStatus.Success) {
      const parseResult = AuthModel.parse(response.responseJson);
      if (parseResult.success == true) {
        BryxLocal.initializeFromAuthModel(parseResult.value);
        callback(apiSuccess(parseResult.value));
      } else {
        callback(apiFailure<AuthModel>(null, parseResult.justification));
      }
    } else {
      callback(apiFailureWithResponse<AuthModel>(response));
    }
  }

  public static updateOffset(
    callback: (result: ApiResult<null>) => void
  ): void {
    BryxApi.http.get("/ts", null, (request, response) => {
      const apiResult = apiResultFromParse(response, ServerTime.parse);
      if (apiResult.success == true) {
        DateUtils.setOffset(
          apiResult.value.time.getTime() - new Date().getTime()
        );
        callback(apiSuccess(null));
      } else {
        config.warn(`Failed to update time offset: ${apiResult.debugMessage}`);
        callback(apiResult);
      }
    });
  }

  public static getEula(callback: (result: ApiResult<Eula>) => void): void {
    const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
      callback(apiResultFromParse(response, Eula.parse));
    };

    BryxApi.http.get("/eula", null, wrappedCallback);
  }

  public static acceptEula(callback: (result: ApiResult<null>) => void): void {
    const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
      callback(apiResultFromParse(response, nullParser));
    };

    BryxApi.http.put(
      "/users/me/eula",
      null,
      null,
      BryxApi.unauthenticatedCallback(wrappedCallback)
    );
  }

  public static signIn(
    token: string,
    callback: (result: ApiResult<AuthModel>) => void
  ): void {
    let refUrl;
    try {
      refUrl = new URL(document.referrer);
    } catch (_err) {}
    const isFrame = window != window.top;
    const authBody = {
      token: token,
      deviceInfo: DeviceUtils.authDeviceInfo,
      appVersion: config.version,
      iframe: isFrame,
      firstArriving:
        isFrame && refUrl && /(\.|^)firstarriving.com$/.test(refUrl.host),
    };

    BryxApi.http.post(
      "/station-boards/session",
      null,
      authBody,
      (request: HttpRequest, response: HttpResponse) => {
        BryxApi.authCallback(request, response, callback);
      }
    );
  }

  public static session(
    callback: (result: ApiResult<AuthModel>) => void
  ): void {
    const authBody = {
      appVersion: config.version,
    };

    BryxApi.http.put(
      "/authorization/",
      null,
      authBody,
      BryxApi.unauthenticatedCallback(
        (request: HttpRequest, response: HttpResponse) => {
          BryxApi.authCallback(request, response, (result) => {
            BryxApi.updateOffset((_) => {
              callback(result);
            });
          });
        }
      )
    );
  }

  public static signOut(callback?: (result: ApiResult<null>) => void): void {
    const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
      if (callback != null) {
        callback(apiResultFromParse(response, nullParser));
      }
    };
    BryxApi.http.del(
      "/station-boards/session",
      null,
      BryxApi.unauthenticatedCallback(wrappedCallback)
    );
  }

  // Jobs

  public static getStreetView(
    jobId: string,
    callback: (result: StreetViewResult) => void
  ): void {
    const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
      if (response.statusCode == 200) {
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result == "string") {
            callback({ key: "available", imageUrl: reader.result });
          } else {
            callback({ key: "error", message: "Failed to encode image" });
          }
        };
        reader.onerror = () =>
          callback({
            key: "error",
            message: `Failed to encode image: ${reader.error}`,
          });
        reader.readAsDataURL(response.response);
      } else if (response.statusCode == 204) {
        callback({ key: "none" });
      } else {
        const failure = apiFailureWithResponse(response);
        callback({
          key: "error",
          message: !failure.success ? failure.message : "Unknown problem",
        });
      }
    };
    BryxApi.http.get(
      `/jobs/${jobId}/street-view`,
      null,
      BryxApi.unauthenticatedCallback(wrappedCallback),
      undefined,
      "blob"
    );
  }

  public static sendSupportTicket(
    from: string,
    subject: string | null,
    type: string,
    body: string,
    image: File | null,
    callback: (result: ApiResult<null>) => void
  ) {
    const sendRequest = (
      imageData: {
        fileName: string;
        base64Data: string;
        contentType: string;
      } | null
    ) => {
      const attachments = [
        {
          contentType: "text/plain",
          fileName: "localStorage.txt",
          data: SupportUtils.bryxItemsAttachment(),
        },
        {
          contentType: "text/plain",
          fileName: "logs.txt",
          data: SupportUtils.logsAttachment(),
        },
        {
          contentType: "text/plain",
          fileName: "deviceInfo.txt",
          data: SupportUtils.deviceInfoAttachment(),
        },
      ];
      if (imageData != null) {
        attachments.push({
          fileName: imageData.fileName,
          contentType: imageData.contentType,
          data: imageData.base64Data,
        });
      }
      const requestBody = {
        body: body,
        email: from,
        subject: subject,
        type: type,
        platform: "universal",
        attachments: attachments,
      };

      const wrappedCallback = (
        request: HttpRequest,
        response: HttpResponse
      ) => {
        callback(apiResultFromParse(response, nullParser));
      };

      BryxApi.http.post(
        "/support",
        null,
        requestBody,
        BryxApi.unauthenticatedCallback(wrappedCallback)
      );
    };

    if (image != null) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        if (typeof reader.result == "string") {
          const splitParts = reader.result.split(";base64,");
          const contentType = splitParts[0].replace("data:", "");
          const imageBase64Data = splitParts[1];
          sendRequest({
            fileName: image.name,
            base64Data: imageBase64Data,
            contentType: contentType,
          });
        } else {
          callback(
            apiFailure(null, `Unable to load image file: ${reader.error}`)
          );
        }
      };
      reader.onerror = () =>
        callback(
          apiFailure(null, `Unable to load image file: ${reader.error}`)
        );
    } else {
      sendRequest(null);
    }
  }

  // URLs

  public static getImageUrl(imageId: string, thumb: boolean = false): string {
    return `${BryxApi.apiRoot}/images/${imageId}?${
      thumb ? "thumbnail=true" : ""
    }&apiKey=${BryxLocal.getApiKey()}&bryxType=stationBoard`;
  }

  public static getHydrantSvgUrl(color: string): string {
    return `${BryxApi.apiRoot}/assets/hydrant.${encodeURIComponent(color)}.svg`;
  }

  // Site Survey

  public static getSiteSurveys(
    jobId: string,
    callback: (result: ApiResult<SiteSurvey>) => void
  ): void {
    const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
      if (callback != null) {
        callback(apiResultFromParse(response, SiteSurvey.parse));
      }
    };
    BryxApi.http.get(
      `/jobs/${jobId}/legacy-site-survey`,
      null,
      BryxApi.unauthenticatedCallback(wrappedCallback)
    );
  }

  // API WebSocket Functions

  public static subscribeToNewJobs(
    key: string,
    fastForwardMode: "reset" | "resume",
    onUpdate: (result: ApiResult<JobsListUpdate>) => void
  ) {
    const params = {
      model: "station",
      fastForwardMode: fastForwardMode,
    };
    BryxWebSocket.shared.addSubscriber(
      key,
      "jobs",
      (message) => {
        const update = parseJobsListUpdate(message);
        if (update.success == true) {
          // Ignore `null` updates
          if (update.value != null) {
            onUpdate(apiSuccess(update.value));
          }
        } else {
          onUpdate(update);
        }
      },
      0,
      params
    );
  }

  public static changeJobListSubscription(
    key: string,
    fastForwardMode: "reset" | "resume",
    resubscribe: boolean
  ) {
    const params = {
      model: "station",
      fastForwardMode: fastForwardMode,
    };
    BryxWebSocket.shared.changeSubscription(key, "jobs", params, resubscribe);
  }

  public static acknowledgeJobsListUpdates(
    updateIds: string[],
    completion: (result: ApiResult<null>) => void
  ) {
    const data = {
      type: "ack",
      updateIds: updateIds,
    };
    BryxWebSocket.shared.sendUpdate("jobs", data, completion);
  }

  public static subscribeToMap(
    key: string,
    topRightBounds: number[],
    bottomLeftBounds: number[],
    layers: LayerType[],
    onUpdate: (result: ApiResult<MapUpdate>) => void
  ) {
    BryxWebSocket.shared.addSubscriber(
      key,
      "maps",
      (message) => {
        const update = parseMapUpdate(message);
        if (update.success == true) {
          // Ignore `null` updates
          if (update.value != null) {
            onUpdate(apiSuccess(update.value));
          }
        } else {
          onUpdate(update);
        }
      },
      0,
      {
        ne: topRightBounds,
        sw: bottomLeftBounds,
        layers: layers.map((l) => LayerType[l]),
      }
    );
  }

  public static changeMapSubscription(
    key: string,
    topRightBounds: number[],
    bottomLeftBounds: number[],
    layers: LayerType[]
  ) {
    BryxWebSocket.shared.changeSubscription(
      key,
      "maps",
      {
        ne: topRightBounds,
        sw: bottomLeftBounds,
        layers: layers.map((l) => LayerType[l]),
      },
      true
    );
  }

  public static subscribeToBoard(
    key: string,
    onUpdate: (result: ApiResult<BoardUpdate>) => void
  ) {
    BryxWebSocket.shared.addSubscriber(
      key,
      "stationBoard",
      (message) => {
        const update = parseBoardUpdate(message);
        if (update.success == true) {
          // Ignore `null` updates
          if (update.value != null) {
            onUpdate(apiSuccess(update.value));
          }
        } else {
          onUpdate(update);
        }
      },
      0
    );
  }

  public static subscribeToScu(
      key: string,
      scuId: string,
      onUpdate: (result: ApiResult<BoardUpdate>) => void
  ) {
    BryxWebSocket.shared.addSubscriber(
        key,
        `scus/${scuId}`,
        (message) => {
          const update = parseBoardUpdate(message);
          if (update.success == true) {
            // Ignore `null` updates
            if (update.value != null) {
              onUpdate(apiSuccess(update.value));
            }
          } else {
            onUpdate(update);
          }
        }
    );
  }

  public static subscribeToWeather(
    key: string,
    current: boolean,
    forecast: WeatherForecastType,
    onUpdate: (result: ApiResult<WeatherUpdate>) => void
  ) {
    BryxWebSocket.shared.addSubscriber(
      key,
      "weather",
      (message) => {
        const update = parseIdleOverlayUpdate(message);
        if (update.success == true) {
          // Ignore `null` updates
          if (update.value != null) {
            onUpdate(apiSuccess(update.value));
          }
        } else {
          onUpdate(update);
        }
      },
      0,
      {
        current,
        forecast: forecast
          ? StationBoardInactiveLayer[forecast as StationBoardInactiveLayer]
          : null,
      }
    );
  }

  public static changeWeatherSubscription(
    key: string,
    current: boolean,
    forecast: WeatherForecastType
  ) {
    BryxWebSocket.shared.changeSubscription(
      key,
      "maps",
      {
        current,
        forecast,
      },
      true
    );
  }

  public static subscribeToIdleLayers(
    key: string,
    layers: ["openJobs"],
    onUpdate: (result: ApiResult<IdleLayersUpdate>) => void
  ) {
    BryxWebSocket.shared.addSubscriber(
      key,
      "sbIdle",
      (message) => {
        const update = parseIdleLayersUpdate(message);
        if (update.success == true) {
          // Ignore `null` updates
          if (update.value != null) {
            onUpdate(apiSuccess(update.value));
          }
        } else {
          onUpdate(update);
        }
      },
      0,
      { layers }
    );
  }

  public static changeIdleLayersSubscription(
    key: string,
    layers: ["openJobs"]
  ) {
    BryxWebSocket.shared.changeSubscription(key, "sbIdle", { layers }, true);
  }

  public static unsubscribe(key: string) {
    BryxWebSocket.shared.removeSubscriber(key);
  }
}
